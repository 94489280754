import React, { Component } from 'react';

import Button from 'components/atoms/Button';
import './style.scss';
import { tl } from 'utils/Translations';

import { Animated } from "react-animated-css";

class ChapterCard extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: {}, width: window.innerWidth };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.goToChapter = this.goToChapter.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }

  openModal() {
    if (this.props.chapter.progress.status === "DONE") this.goToChapter();
    else {
      this.state.modal.show = true;
      this.setState(this.state);
    }
  };

  closeModal() {

    let isMobile = this.state.width <= 768;

    let vid = this.props.chapter.videoDescriptionURL ? (isMobile ? document.getElementById('chapterVideoMobile') : document.getElementById('chapterVideo')) : null;
    if (vid) vid.load(); //reload the video so the dom is refreshed    

    this.state.modal.show = false;
    this.setState(this.state);
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  };

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth });
  };

  goToChapter(result = false) {
    this.props.onClick(this.props.chapter._id, result)
  }

  render() {

    return (
      <Animated
        animationIn="fadeInUp"
        animationOut="fadeOutDown"
        animationInDelay={150}
        animationOutDelay={200}
        className={`${this.props.className || ''}`}
        isVisible={true}>
        <div className="Dad-card Dad-card--chapter">
          <div className="Dad-card__front">
            <div className="Dad-card__head">
              {
                this.props.chapter.progress.status === "TO_DO" &&
                <div className="Dad-card__status Dad-card__status--todo">
                  <span className="Dad-card__status-icon"></span>
                  <span className="Dad-card__status-label">
                    {tl(this.props.chapter.progress.status)}
                  </span>
                </div>
              }
              {
                this.props.chapter.progress.status === "IN_PROGRESS" &&
                <div className="Dad-card__status Dad-card__status--in-progress">
                  <span className="Dad-card__status-label">
                    {tl(this.props.chapter.progress.status)}
                  </span>
                  <div className="Dad-card__status-progress">
                    <div className="Dad-card__status-active" style={{
                      width: `${this.props.chapter.progress.progress}%`
                    }}></div>
                  </div>
                </div>
              }
              {
                this.props.chapter.progress.status === "DONE" &&
                <div className="Dad-card__status Dad-card__status--done">
                  <span className="Dad-card__status-icon"></span>
                  <span className="Dad-card__status-label">
                    {tl(this.props.chapter.progress.status)}
                  </span>
                </div>
              }

              {/* Feature coming soon          
              <div className="Dad-card__menu">
                <span></span>
                <span></span>
                <span></span>
              </div> */}
            </div>
            <div className="Dad-card__body">
              <div className="Dad-card__icon" style={{ backgroundImage: 'url(' + this.props.chapter.iconURL + ')' }}></div>
              <h2 className="Dad-card__title">
                {this.props.chapter.name}
              </h2>
              <h3 className="Dad-card__subtitle">
                {this.props.chapter.timer ? this.props.chapter.timer : null}
              </h3>
            </div>
            <div className="Dad-card__footer">
              <Button
                className="secondary"
                size="S"
                onClick={() => { this.goToChapter(false) }}
              >
                {
                  this.props.chapter.progress.status === 'IN_PROGRESS' ? tl('ResumeTranslation') : (this.props.chapter.progress.status == 'TO_DO' ? tl('MainOrganismsChapterCardStart') : tl('MainOrganismsChapterCardRedo'))
                }
              </Button>
              <Button
                className={'secondary ' + (this.props.chapter.progress.status === 'DONE' ? '' : 'hidden')}
                size="S"
                onClick={() => { this.goToChapter(true) }}
              >
                {tl('Result')}
              </Button>
            </div>
          </div>
        </div>
      </Animated>
    )
  }
}
export default ChapterCard;