import React from 'react';
import './style.scss';

class LayoutSidebar extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="Dad-layout-side">
        <div className="Dad-layout-side__container">
          <div className="Dad-layout-side__content">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
export default LayoutSidebar;
