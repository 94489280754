import axios from "../config/axios";

const getLinks = async () => (await axios.get(`/contents/links/`)).data;
const getFooterLinks = async (instance_id) =>
  (
    await axios.get(
      `/contents/links/footer${instance_id ? "/" + instance_id : ""}`
    )
  ).data;

export default {
  getLinks,
  getFooterLinks,
};
