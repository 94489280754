import React, { Component } from 'react';
import Auth from 'resources/auth';
import { tl } from 'utils/Translations';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import NickNameSelector from 'components/organisms/NickNameSelector'
import Error from 'components/atoms/Error';

class NickNamePicker extends Component{
  constructor(props){
    super(props)
    this.state={
      username: props.username ? props.username : "",
      nicknames: [],
      ageRange: null,
      userNameExists : false,
      successMessage: false,
      formValid: false,
      errorMessage: '',
      errorTitle: '',
      errorLogin: false
    }

    this.onInputChange = this.onInputChange.bind(this)
    this.selectNickName = this.selectNickName.bind(this)
    this.redirectLanding = this.redirectLanding.bind(this)
    this.itemClicked = this.itemClicked.bind(this)
  }

  onInputChange(e){
    this.setState({
        username: e.value,
        formValid: e.value.length > 0 && (this.state.ageRange !== null)
      }, 
      () => this.props.nickNameChange({ username: e.value, error: this.state.userNameExists })
    );
  }

  redirectLanding(){
    this.props.history.push("/");
  }

  selectNickName (input){
    this.setState({ 
      username: input.value, userNameExists: false }, 
      () => this.props.nickNameChange({ username: this.state.username, error: this.state.userNameExists })
    )
  }

  itemClicked(ageRange){
    this.setState({ ageRange: ageRange }, () => this.setState({ formValid: this.state.username.length > 0 }));
  }

  render () {
    return (
      <div>
        <Label value={tl('ChoseYourPseudo')} />
        <Input 
          type="text" 
          placeholder={tl('MainPagesRegisterNickNamePickUsername')} 
          onInputChange={this.onInputChange}
          disabled={this.props.userDataDisabled}
          name="login" 
          value={this.state.username}
        />
        
        {this.props.userNameExists &&
          <Error
            errorLogin={this.props.userNameExists}
            errorMessage={this.props.errorMessage}
            errorTitle={this.state.errorTitle }
          />
        }

        {this.props.userNameExists &&
          <div className="Nickname">
            {this.props.proposedNicknames.map((nick, i) =>
                <NickNameSelector
                  key={`nick-${i}`}
                  nickname={nick}
                  selectNickName={this.selectNickName}
                />
            )}
          </div>
        }
      </div>
    )
  }
}

export default NickNamePicker;
