import React, { Component } from 'react';
import { tl } from 'utils/Translations';
import './style.scss';

class ListIcon extends Component {
  constructor(props) {
    super(props);
    this.state = { obj: props.obj, className: props.className };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.obj !== prevState?.obj) {
      return {
        obj: nextProps.obj
      };
    }
    return null;
  }

  render() {
    return (
      <li className="Dad-List-item">
        <div className="Dad-List-item__icon">
          {/* { this.state.obj.image } */}
          <img src={this.state.obj.image} />
        </div>
        <h3 className="Dad-List-item__title">
          {tl(this.state.obj.title)}
        </h3>
        <p className="Dad-List-item__description">
          {tl(this.state.obj.description)}
        </p>
      </li>
    )
  }
}

export default ListIcon;
