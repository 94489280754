import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { tl } from 'utils/Translations';

import Button from 'components/atoms/Button';

import './style.scss';
import ArrowBack from 'components/atoms/ArrowBack';

class Panel extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className={ `Dad-panel ${this.props.className}` }>
        <div className="Dad-panel__front">
          <div className="Dad-panel__icon">
            <img src={this.props.icon} />
          </div>
          <div className="Dad-panel__content">
            <h2 className="Dad-panel__title">
              {this.props.title}
            </h2>
            <p className="Dad-panel__description">
              {this.props.description}
            </p>
          </div>
        </div>
        <div className="Dad-panel__back">
          <div className="Dad-panel__content">
            {this.props.children}
          </div>
          
        </div>
        
        <div className="Dad-panel__cta" onClick={this.props.onClick}>
          <Button
            onClick={ this.props.onClick }
            className="secondary"
          >
            {this.props.labelCTA}
          </Button>
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  labelCTA: PropTypes.string.isRequired,
}

export default Panel;
