import React, { Component} from 'react'
import PropTypes from 'prop-types';
import Rocket from 'assets/images/rocket-timeline.svg';
import './style.scss';

class RocketTimeline extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="Dad-rocket-timeline">
        <div className="Dad-rocket-timeline__gauge">
          <div className="Dad-rocket-timeline__default"></div>
          <div className="Dad-rocket-timeline__current" style={{
            height: `${this.props.progress}%`
          }}></div>
          <div className="Dad-rocket-timeline__rocket" style={{
            bottom: `${ this.props.progress }%`
          }}>
            <img src={Rocket} alt="" />
          </div>
        </div>
      </div>
    )
  }
}

RocketTimeline.defaultProps = {
  progress: 0
}

RocketTimeline.propTypes = {
  progress: PropTypes.number
}

export default RocketTimeline;

