import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class ProgressBar extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className={`${ this.props.className } Dad-Progressbar`}>
        <div className="Dad-Progressbar--background" style={{
          "backgroundColor": this.props.backgroundColor,
          "height": this.props.height
        }}></div>
        <div className="Dad-Progressbar--progressbar" style={{
          width: `${this.props.width}`,
          "height": this.props.height,
          "backgroundColor": this.props.fillColor
        }}></div>
      </div>
    )
  }
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  backgroundColor: PropTypes.string,
  fillColor: PropTypes.string,
}

export default ProgressBar;
