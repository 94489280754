import React, { Component } from "react";
import { tl } from "utils/Translations";
import style from "./style.scss";

// Assets
import FirstTimeIcon from "assets/images/first-connexion.svg";
import ContinueDadIcon from "assets/images/dad-continue.svg";

// Atoms
import Label from "components/atoms/Label";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import Error from "components/atoms/Error";
// Molecules
import Panel from "components/molecules/Panel";

// Organisms
import HeaderBack from "components/organisms/Headers/HeaderBack";

// Templates
import LayoutFullScreen from "components/templates/fullScreen";

// Pages

// Resources
import AuthFactory from "resources/auth";
import InstanceFactory from "resources/instance";
import ConnexionTypeFactory from "resources/connexionType";

// Utils
import Storage from "utils/Storage";

class Qualification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revealNickName: false,
      login: "",
      errorLogin: false,
      errorMessage: false,
      logged: false,
      instance_alias: props.match.params.instance_alias,
      connexionType: "pseudo",
    };

    this.selectFirstTimeDad = this.selectFirstTimeDad.bind(this);
    this.selectDad = this.selectDad.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  componentDidMount() {
    return InstanceFactory.checkInstanceExists({
      instance_alias: this.props.match.params.instance_alias,
    })
      .then((resu) => {
        if (resu == false) {
          this.props.history.push("/");
        }
        return AuthFactory.isAuthenticated()
          .then((loggedResponse) => {
            this.props.history.push(
              this.props.match.params.instance_alias
                ? "chapters"
                : `${this.state.instance_alias}/chapters`
            ); //si on a déjà un alias dans l'url alors simple redirection
          })
          .catch((err) => {
            ConnexionTypeFactory.getConnexionType({
              instance_alias: this.props.match.params.instance_alias,
            }).then((data) => {
              this.setState({
                connexionType: data.code,
              });
            });
          });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  selectFirstTimeDad() {
    if (this.state.connexionType === "plazza") {
      this.go2PlazzaOAuth();
    } else {
      this.props.history.push(
        `/${this.props.match.params.instance_alias}/register`
      );
    }
  }

  selectDad() {
    if (this.state.connexionType === "plazza") {
      this.go2PlazzaOAuth();
    } else {
      this.setState({
        revealNickName: true,
      });
    }
  }

  go2PlazzaOAuth() {
    AuthFactory.generateStateOauthPlazza().then((state) => {
      Storage.setItem({
        key: `instanceAliasBeforeOauth`,
        value: this.props.match.params.instance_alias,
      });
      window.location.href =
        process.env.PLAZZA_URL_AUTHORIZE +
        `?client_id=${process.env.PLAZZA_CLIENT_ID}&response_type=code&state=${state}`;
    });
  }

  onInputChange(el) {
    this.setState({
      login: el.value,
    });
  }

  onLogin(e) {
    e.preventDefault();
    AuthFactory.login({
      nickname: this.state.login,
      instance: this.state.instance_alias,
    })
      .then((loginResponse) => {
        this.props.history.push(
          `/${this.props.match.params.instance_alias}/chapters`
        );
      })
      .catch((loginResponseError) => {
        this.setState({
          errorTitle: tl("MainComponentsPagesAuthErrorTitle"),
          errorMessage: tl(
            "MainComponentsPagesAuthErrorMessage",
            this.state.login
          ),
          errorLogin: true,
        });
      });
  }

  render() {
    if (!this.state) return null;
    else
      return (
        <div>
          <HeaderBack
            reverse={true}
            _onBackClick={() =>
              this.props.history.push(`/${this.state.instance_alias}`)
            }
            {...this.props}
          />
          <LayoutFullScreen>
            <div className="Dad-qualification">
              <section className="Dad-qualification__left">
                <Panel
                  title={tl("MainPagesQualificationFirsttime")}
                  description={tl("MainPagesQualificationFirsttimedesc")}
                  icon={FirstTimeIcon}
                  labelCTA={tl("MainPagesQualificationCreateProfile")}
                  onClick={this.selectFirstTimeDad}
                />
              </section>
              <section className="Dad-qualification__right">
                <Panel
                  className={
                    this.state.revealNickName ? "Dad-panel--reveal" : ""
                  }
                  title={tl("MainPagesQualificationContinue")}
                  description={tl("MainPagesQualificationContinuedesc")}
                  icon={ContinueDadIcon}
                  labelCTA={tl("MainPagesQualificationRedoDad")}
                  onClick={this.selectDad}
                >
                  <form className="Auth__form" onSubmit={this.onLogin}>
                    <Input
                      onInputChange={this.onInputChange}
                      type="text"
                      placeholder={tl("MainComponentsAuthInputEmail")}
                      name="login"
                      value={this.state.login}
                    />
                    {this.state.errorLogin ? (
                      <Error
                        errorLogin={this.state.errorLogin}
                        errorMessage={this.state.errorMessage}
                        errorTitle={this.state.errorTitle}
                      />
                    ) : null}
                    <div className="Auth__form-button">
                      <Button
                        onClick={this.onLogin}
                        className="primary"
                        disabled={this.state.login.length < 1}
                      >
                        {tl("MainComponentsAuthStartButton")}
                      </Button>
                    </div>
                  </form>
                </Panel>
              </section>
            </div>
          </LayoutFullScreen>
        </div>
      );
  }
}

export default Qualification;
