const language =
  window.localStorage.getItem("lang") ||
  (window.navigator.userLanguage ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.systemLanguage)
    .split("-")[0]
    .toLowerCase();
const bank = {};

const setLanguage = lang => {
  window.localStorage.setItem("lang", lang.toLowerCase());
  window.location.reload();
};

const getLanguage = () => language;
const tl = (id, ...args) => {
  let str = bank[id];
  if (!str) return id;
  if (args) {
    args.forEach((arg, i) => {
      str = str.replace(new RegExp(`\\$${i + 1}`, "g"), arg);
    });
  }
  return str;
};
const use = translations => {
  Object.assign(bank, translations);
};

export { setLanguage, getLanguage, tl, use };
