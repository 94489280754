import React, { Component } from 'react'
import PropTypes from 'prop-types';

import './style.scss';


class Label extends Component {
  constructor(props){
    super(props)
  }

  render () {
    return (
      <label htmlFor={this.props.forRef} className={`Dad-label ${this.props.className || ''}`}>
        {this.props.value}
      </label>
    )
  }
}

Label.propTypes = {
  forRef: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ])
}


export default Label;