import React, { Component } from 'react';
import '../global.scss';
import './style.scss';

import Button from 'components/atoms/Button';
import { tl } from 'utils/Translations';


class HeroLanding extends Component {
  constructor(props){
    super(props);
  }

  render () {
    let styles = {}

    if (this.props.backgroundImage !== null) {
      styles['backgroundImage'] = `url(${this.props.backgroundImage})`;
      styles['backgroundSize'] = "cover";
      styles["backgroundRepeat"] = "no-repeat";
    }

    return (
      <section className="Dad-hero Dad-hero--landing" style={styles}>
        <div className="Dad-hero__container">
          <div className="Dad-hero__content container">
            <div className="col-12">
              <h1 className="Dad-hero__headline">
                {tl('LandingPageHeroBrand')}
              </h1>
            </div>
            <div className="col-md-4 offset-md-4">
              <h2 className="Dad-hero__baseline">
                {tl('LandingPageHeroSubtitle')}
              </h2>
            </div>
            <div className="col-md-2 offset-md-5">
              <Button 
                className="primary" 
                type="primary" 
                onClick={this.props._handleClick}>
                  {tl('LandingPageStartButton')}
              </Button>
            </div>
          </div>
        </div>
      </section>
    )
  }
}


export default HeroLanding;
