import React, { Component } from "react";

import style from "./style.scss";

import Avatar from "components/atoms/Avatar";
import Upload from "components/atoms/Upload";
import Link from "components/atoms/Link";

import Title from "components/molecules/Title";
import { tl } from "utils/Translations";
import Modal from "components/organisms/Modal";

import DefautAvatar from "assets/images/icon-upload.svg";

class Profile extends Component {
  constructor(props) {
    super(props);
    let a =
      props.selectedAvatar !== null ? props.selectedAvatar.path : DefautAvatar;

    this.state = {
      avatar: a,
      avatarObj: props.selectAvatar,
      avatars: props.avatars,
      modal: false,
    };

    this.openModal = this.openModal.bind(this);
    this.selectAvatar = this.selectAvatar.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.selectedAvatar?.path !== prevState?.avatarObj?.path) {
      return {
        avatar:
          nextProps.selectedAvatar && nextProps.selectedAvatar.path
            ? nextProps.selectedAvatar.path
            : DefautAvatar,
        avatarObj: nextProps.selectAvatar,
      };
    }
    return null;
  }

  openModal() {
    this.setState({
      modal: { show: true },
    });
    document.body.classList.add("is-hidden");
  }

  selectAvatar(avatar) {
    this.setState(
      {
        avatar: avatar.path,
        avatarObj: avatar,
      },
      () => {
        this._closeModal();
        this.props.selectAvatar({ avatar: avatar });
      }
    );
  }

  _closeModal() {
    this.setState({
      modal: false,
    });
    document.body.classList.remove("is-hidden");
  }

  render() {
    return (
      <div className="choose-avatar">
        <div className="Dad-upload-image">
          <Upload onClick={this.openModal} image={`${this.state.avatar}`} />
          <p onClick={this.openModal} className="Dad-link upload-avatar-link">
            {tl("PickAvatarTranslation")}
          </p>
        </div>
        <Modal
          open={this.state.modal}
          title={tl("MainRegisterProfileHeader")}
          _onClose={() => this.setState({ modal: false })}
        >
          {this.state.avatars.map((avatar, i) => {
            return (
              <div className="col-4 col-md-2" key={i}>
                <Avatar
                  className={`${this.props.selectedAvatar &&
                    this.props.selectedAvatar._id == avatar._id
                    ? "selected"
                    : ""
                    }`}
                  onClick={this.selectAvatar.bind(this, avatar)}
                  image={`${avatar.path}`}
                />
              </div>
            );
          })}
        </Modal>
        {/* <Modal
          open={ this.state.modal.show }
          header={ this.state.modal }
          _onClose={ this._closeModal }
          isClosable
          modalClass='Modal Modal--avatar'>
          <div className="Modal__content">
            <Title type="primary" outline={false} title={tl('MainRegisterProfileHeader')} />
            <div className="Modal__wrapper-avatars">
            {
              this.state.avatars.map((avatar, i) =>{
                return (
                <div onClick={this.selectAvatar.bind(this,avatar)}
                  key={`avatar-${i}`}
                  className={`Modal__avatar col-6 col-md-2 col-lg-2 col-xl-2 ${this.state.avatar === avatar ?  'Modal__avatar--selected' : ''}`}>
                  <Avatar  className="avatar"  image={avatar.path} />
                </div>
                )
              }
              )
            }
            </div>
          </div>
        </Modal> */}
      </div>
    );
  }
}

export default Profile;
