import React, { Component } from "react";
import { tl } from "utils/Translations";
import Button from "components/atoms/Button";

import Profile from "components/organisms/Register/Profile";
import Nickname from "components/organisms/Register/Nickname";
import Jobs from "components/organisms/Register/Jobs";
import Regions from "components/organisms/Register/Region";

import "./style.scss";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.userNameSelected ? props.userNameSelected : "",
      nickNameSelected: props.userNameSelected ? props.userNameSelected : null,
      nicknames: [],
      ageRange: null,
      userNameExists: false,
      successMessage: false,
      formValid: false,
      currentStep: 0,
      icons: props.icons,
      jobs: props.jobs,
      regions: props.regions,
      jobSelected: null,
      regionSelected: null,
      avatarSelected: null,
      avatar: false,
      instanceAlias: props.instanceAlias,
      answeredSteps: [],
    };

    this.steps = ["Avatar", "Métier", "Région"];
    this.onInputChange = this.onInputChange.bind(this);
    this.selectNickName = this.selectNickName.bind(this);
    this.redirectLanding = this.redirectLanding.bind(this);
    this.itemClicked = this.itemClicked.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this.selectAvatar = this.selectAvatar.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.setNextStep = this.setNextStep.bind(this);
    this.startDad = this.startDad.bind(this);
    this._setProgress = this._setProgress.bind(this);
    this.nickNameChange = this.nickNameChange.bind(this);
  }

  onInputChange(e) {
    this.setState({
      username: e.value,
      formValid: e.value.length > 0 && this.state.ageRange !== null,
    });
  }

  redirectLanding() {
    this.props.history.push("/");
  }

  selectNickName(input) {
    this.setState({
      successMessage: true,
      userNameExists: false,
      nicknames: false,
      username: input.value,
    });
  }

  itemClicked(ageRange) {
    this.setState({ ageRange: ageRange }, () => this.setState({ formValid: this.state.username.length > 0, }));
  }

  _handleClick(step) {
    if (!this.state.answeredSteps.length) return;
    if (_.indexOf(this.state.answeredSteps, step) !== -1) {
      let passedStep = _.indexOf(this.state.answeredSteps, step) !== -1;
      let curStep = this.state.currentStep;
      if (passedStep > -1) curStep = _.indexOf(this.steps, step) + 1;
      this.setState({
        currentStep: curStep,
      });
    }
  }

  selectAvatar(avatar) {
    let answered = this.state.answeredSteps;
    if (_.indexOf(answered, "Avatar") === -1) answered.push("Avatar");
    this.setState({ avatarSelected: avatar.avatar, answeredSteps: answered }, () => this._setProgress());
  }

  setNextStep() {
    this.setState({ currentStep: this.state.currentStep + 1, answeredSteps: ["Avatar"] });
  }

  selectRegion(region) {
    this.setState({ regionSelected: region.region._id });
  }

  startDad() {
    this.props.completeRegister({
      regionSelected: this.state.regionSelected,
      jobSelected: this.state.jobSelected,
      avatarSelected: this.state.avatarSelected,
      nickNameSelected: this.state.nickNameSelected,
    });
  }

  _setProgress() {
    let total = 0;
    const steps = ['avatarSelected','nickNameSelected','jobSelected','regionSelected'];
    steps.forEach(step => this.state[step] && total++);
    this.props._onProgress((total / steps.length) * 100);
  }

  nickNameChange(args) {
    this.setState({ nickNameSelected: args.username, hasError: args.error },() => this._setProgress());
  }

  render() {
    return (
      <div>
        <form
          className="container"
          onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <div className="col-md-6 offset-md-3">
            <div className="Form-content">
              <div className="Dad-spacing__M">
                <Profile
                  avatars={this.state.icons}
                  selectAvatar={this.selectAvatar}
                  selectedAvatar={this.state.avatarSelected}
                  onClickNext={this.setNextStep}
                />
              </div>

              <div
                className={`Dad-spacing__M Dad-form-row ${
                  this.state.avatarSelected || this.state.nickNameSelected ? "Dad-form-row--reveal" : ""
                }`}
              >
                <Nickname
                  errorMessage={this.props.errorMessage}
                  userNameExists={this.props.userNameExists}
                  proposedNicknames={this.props.proposedNicknames}
                  instanceAlias={this.state.instanceAlias}
                  username={this.props.userNameSelected}
                  userDataDisabled={this.props.userDataDisabled}
                  nickNameChange={this.nickNameChange}
                />
              </div>

              <div
                className={`Dad-spacing__M Dad-form-row ${
                  this.state.nickNameSelected ? "Dad-form-row--reveal" : ""
                }`}
              >
                <Jobs
                  jobs={this.state.jobs}
                  handleJobSelection={(job) => this.setState({ jobSelected: job }, () => this._setProgress())}
                  selectedJob={this.state.jobSelected}
                />
              </div>

              <div
                className={`Dad-spacing__M Dad-form-row ${
                  this.state.jobSelected ? "Dad-form-row--reveal" : ""
                }`}
              >
                <Regions
                  regions={this.state.regions}
                  handleRegionSelection={(region) => this.setState({ regionSelected: region }, () => this._setProgress())}
                  selectedRegion={this.state.regionSelected}
                />
              </div>

              <div
                className={`Dad-spacing__M Dad-form-row ${
                  this.state.regionSelected && !this.state.hasError
                    ? "Dad-form-row--reveal"
                    : ""
                }`}
              >
                <Button className="primary" onClick={this.startDad}>
                  {tl("MainPagesRegisterStepsStart")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Register;
