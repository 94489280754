import React, { Component } from 'react'
import PropTypes from 'prop-types';

class Funnel extends Component {
  constructor(props){
    super(props)
  }

  render () {
    return (
      <svg width="14px" height="15px" viewBox="0 0 14 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className={ this.props.className }>
          <g id="Emploi-du-temps" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="[Desktop]-Emploi-du-temps" transform="translate(-1205.000000, -551.000000)" fill={this.props.fill}>
                  <g id="Group-3" transform="translate(1205.000000, 548.000000)">
                      <g id="Group-2">
                          <g id="filter-f" transform="translate(0.000000, 3.000000)">
                              <path d="M0.84,0.667333333 L13.16,0.667333333 C13.4589489,0.667520055 13.7313343,0.839032964 13.8606988,1.10854235 C13.9900633,1.37805174 13.9535098,1.69785531 13.7666667,1.93122222 L8.72666667,8.232 C8.61604509,8.36988362 8.55569342,8.54133722 8.55555556,8.71811111 L8.55555556,12.7228889 C8.55555556,12.9677001 8.44029343,13.1982244 8.24444444,13.3451111 L6.68888889,14.5117778 C6.4532085,14.6885381 6.13788811,14.7169705 5.87438943,14.5852211 C5.61089074,14.4534718 5.44444444,14.184156 5.44444444,13.8895556 L5.44444444,8.71733333 C5.44413,8.54082633 5.38378832,8.3696754 5.27333333,8.232 L0.233333333,1.93122222 C0.0464901701,1.69785531 0.00993668878,1.37805174 0.139301195,1.10854235 C0.268665701,0.839032964 0.54105109,0.667520055 0.84,0.667333333 Z" id="Path"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    )
  }
}

Funnel.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
}


export default Funnel;
