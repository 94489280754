import React, { Component } from 'react'
import PropTypes from 'prop-types';

import './style.scss';

class ArrowBack extends Component {
  constructor(props){
    super(props);
  }

  render () {
    return (
      <React.Fragment>
            <svg width="45px" height="45px" viewBox="0 0 45 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className={this.props.className || ''} onClick={this.props._onBackClick }>
            <g id="Profil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="[Desktop]-Profil-" transform="translate(-59.000000, -39.000000)">
                    <g id="Group-3" transform="translate(60.000000, 40.000000)">
                        <g id="Group-6">
                            <g id="Group-5">
                                <circle id="Oval" stroke={this.props.circleStroke} strokeWidth="2" fillRule="nonzero" cx="21.5" cy="21.5" r="21.5"></circle>
                                <path d="M20.23546,17.680504 L15.4761595,22.4398045 C15.0033876,22.8964228 14.251893,22.8898926 13.7871276,22.4251272 C13.3223623,21.9603619 13.315832,21.2088673 13.7724504,20.7360954 L20.5884916,13.9200542 C20.8139682,13.6932835 21.1205578,13.5657756 21.4403462,13.5657756 C21.7601346,13.5657756 22.0667242,13.6932835 22.2922007,13.9200542 L29.1082419,20.7360954 C29.5532345,21.2106333 29.5411842,21.952723 29.081019,22.4125627 C28.6208538,22.8724025 27.8787558,22.8839277 27.4045328,22.4385996 L22.6452324,17.6792991 L22.6452324,29.2293382 C22.6452324,29.8947784 22.1057864,30.4342244 21.4403462,30.4342244 C20.7749059,30.4342244 20.23546,29.8947784 20.23546,29.2293382 L20.23546,17.6792991 L20.23546,17.680504 Z" id="Path" fill={ this.props.arrowFill} transform="translate(21.434224, 22.000000) rotate(-90.000000) translate(-21.434224, -22.000000) "></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </React.Fragment>
    )
  }
}

ArrowBack.propTypes = {
    className: PropTypes.string,
    circleStroke: PropTypes.string,
    arrowFill: PropTypes.string,
};

export default ArrowBack;
