import axios from '../config/axios';

const getIcons = async (args) => 
  await axios.get(`/contents/avatars/${args.instance_alias}`);

const getJobs = async (args) => 
  await axios.get(`/contents/jobs/${args.instance_alias}`);

const getRegions = async (args) =>
  await axios.get(`/contents/regions/${args.instance_alias}`);

export default {
  getIcons,
  getJobs,
  getRegions
}
