import React, { Component } from 'react'
import ChapterFactory from 'resources/chapter';
import HomePageFactory from 'resources/homepage';
import HeaderPlanning from 'components/organisms/Headers/HeaderPlanning';
import Footer from 'components/organisms/Footer';
import HeroPlanning from 'components/organisms/Hero/Planning';
import Card from 'components/organisms/Cards/Chapter'
import { Tooltip } from 'react-tippy';
import Loader from 'components/organisms/Loader';
import { tl } from 'utils/Translations';
import Auth from 'resources/auth';

import Checkbox from 'components/atoms/Icons/Checkbox';
import Funnel from 'components/atoms/Icons/Funnel';
import NavBar from 'components/organisms/NavBar';



class Chapters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chapters: [],
      countCompleted: 0,
      chaptersLength: null,
      logged: false,
      loading: true,
      userInfo: [],
      instance_alias: props.match.params.instance_alias,
      open: false,
      chaptersFilters: [
        {
          _id: 1,
          label: 'DadTooltip__AllChapters',
          status: true
        },
        {
          _id: 2,
          label: 'TO_DO',
          status: true
        },
        {
          _id: 3,
          label: 'IN_PROGRESS',
          status: true
        },
        {
          _id: 4,
          label: 'DONE',
          status: true
        },
      ],
      headerBackgroundColor: null,
      headerBackgroundImage: null,
    }
    this.setIsOpen = this.setIsOpen.bind(this);
    this.handleChapterClick = this.handleChapterClick.bind(this);
  }

  async componentDidMount() {
    try {
      const user = await Auth.getUser();
      if (!!user) {
        const chapters = await ChapterFactory.getAllChapters();
        const homePageInfos = await HomePageFactory.getHomePageInfos({ instance_alias: this.state.instance_alias });
        const numberOfCompletedChapters = chapters.filter(c => c.progress.status === 'DONE').length;
        this.setState({
          chapters: chapters,
          logged: true,
          userInfo: user,
          countCompleted: numberOfCompletedChapters,
          chaptersLength: chapters.length,
          loading: false,
          headerBackgroundImage: homePageInfos.navbarPath,
          headerBackgroundColor: homePageInfos.navbarColor
        });
      }
      document.body.addEventListener("click", () => {
        if (this.state.open) this.setIsOpen(false);
      });
    } catch (e) {
      console.log(e);
      this.props.history.push(`/${this.state.instance_alias}/qualification`);
    }
  };

  componentWillUnmount() {
    document.body.removeEventListener('click', () => this.setIsOpen(false));
  };

  handleChapterClick(e, chapterId, result = false) {
    this.props.history.push(`/${this.state.instance_alias}/chapters/${chapterId}` + (result ? `?result=${result}` : ''));
  };

  setIsOpen(option) { this.setState({ open: option }); };

  filter(filter) {
    let filters = this.state.chaptersFilters;
    const base = !filter.status;
    if (filter._id) {
      if (filter._id === 1) {
        filters.forEach(f => {
          f.status = base;
        });
      } else {
        filter.status = base;
        if (filters.filter(f => f._id !== 1).find(f => f.status === false)) { //si je trouve un filtre a false alors on met le filtre all à false si non on le met a true
          filters.find(f => f._id === 1).status = false;
        } else {
          filters.find(f => f._id === 1).status = true;
        }
      }
    } else {
      filters.find(f => f.label == filter.label).status = base;

      if (filters[1].status === false || filters[2].status === false || filters[3].status === false) {
        filters[0].status = false;
      }
      if (filters[1].status === true && filters[2].status === true && filters[3].status === true) {
        filters[0].status = true;
      }
    }
    return Promise.all([
      this.setState({ chaptersFilters: filters }),
      this.setIsOpen(false)
    ])
  }

  render() {
    if (this.state.loading) return <Loader />

    return (
      <div>
        <HeaderPlanning
          {...this.props}
          user={this.state.userInfo}
          backgroundImage={this.state.headerBackgroundImage}
          backgroundColor={this.state.headerBackgroundColor}
        >
          <NavBar
            displayDiagnosticLink={this.state.countCompleted === this.state.chaptersLength}
            history={this.props.history}
            instanceAlias={this.state.instance_alias}
          />
        </HeaderPlanning>

        <HeroPlanning
          goToReport={() => this.props.history.push(`/${this.state.instance_alias}/diagnostic`)}
          startChapter={() => {
            this.props.history.push(`/${this.state.instance_alias}/chapters/${this.state.chapters.find(c => c.progress.status != 'DONE')._id
              }`);
          }}
          nickname={this.state.userInfo.nickname}
          plazzaName={this.state.userInfo.plazza_name}
          chaptersLength={this.state.chaptersLength}
          chaptersCompleted={this.state.countCompleted}
          currentProgress={(this.state.countCompleted / this.state.chaptersLength) * 100}
          chaptersLeft={this.state.chaptersLength - this.state.countCompleted}
          avatar={this.state.userInfo.avatar}
        />
        <div className="Dad-chapters container">
          <div className="Dad-chapters__head">
            <h2 className="Dad-chapters__title">{tl('ChapterTitleTranslation')}</h2>
            <Tooltip
              trigger="click"
              interactive
              unmountHTMLWhenHide="true"
              onRequestClose={() => { this.setIsOpen(false) }}
              position="bottom-left"
              open={this.state.open}
              html={(
                <ul className="Dad-tooltip__checkboxes">
                  {
                    this.state.chaptersFilters.map((c, i) => {
                      return (
                        <li
                          key={i}
                          className={`Dad-tooltip__checkboxes--checkbox ${c.status ? 'active' : 'inactive'}`}
                          onClick={this.filter.bind(this, c)}
                        >
                          <Checkbox fill={c.status ? '#FFF' : 'transparent'} className={c.status ? 'active' : 'inactive'} /> {tl(c.label)}
                        </li>
                      )
                    })
                  }
                </ul>
              )}
            >
              <div className="Dad-chapters__filter" onClick={() => this.setIsOpen(true)}>
                <Funnel fill='#000' />
                {tl('FiltersTranslation')}
              </div>
            </Tooltip>
          </div>
          <div className="Dad-chapters__container row">
            {this.state.chaptersLength > 0 ? (
              this.state.chapters.filter(c => {
                let f = this.state.chaptersFilters.filter(fi => fi.status).map(fi => fi.label);
                return f.indexOf(c.progress.status) != -1;
              }).map((chapter, i) =>
                <div className="col-md-3" key={i}>
                  <Card
                    chapter={chapter}
                    onClick={this.handleChapterClick.bind(chapter._id, this)}
                    key={`chapter - ${i}`} />
                </div>
              )
            ) : null
            }
          </div>
        </div>
        <Footer match={this.props.match} />
      </div>
    )
  }
}

export default Chapters;
