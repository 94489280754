import React from 'react';
import IconChapter from 'components/atoms/Icons/Chapter';
import IconDiagnostic from 'components/atoms/Icons/Diagnostic';
import { tl } from 'utils/Translations';


const NavBar = ({ displayDiagnosticLink, history, instanceAlias }) => {
  return (
    <React.Fragment>
      {
        [
          { 
            _id: 0,
            label: 'HeaderChapters',
            active: true,
            color: "#FF7900",
            icon: <IconChapter fill="#FF7900" />,
            visible: true,
            go: 'chapters' 
          },
          {
            _id: 1,
            label: 'HeaderDiagnostic',
            icon: < IconDiagnostic fill = "#FFF" />,
            visible: displayDiagnosticLink,
            go: 'diagnostic'
          },
        ].filter(link => link.visible)
        .sort((a, b) => a._id - b._id)
        .map((link) =>
            <a 
              className={`Dad-header-link ${link.active && 'Dad-header-link--active'}`}
              style={{ color: link.color, 'fontWeight': link.active ? 'bold' : '' }}
              key={link._id}
              onClick={() => history.push(`/${instanceAlias}/${link.go}`)}
            >
              {link.icon}
              {tl(link.label)}
            </a>
          )
      }
    </React.Fragment>
  )
}

export default NavBar;