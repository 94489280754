import "regenerator-runtime/runtime"; 
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/pages/App';
import 'index.html';
import "normalize.css/normalize.css";
import "bootstrap-4-grid/scss/grid.scss";
import 'scss/global.scss';
import 'react-tippy/dist/tippy.css';
import 'assets/images/rocket.svg';
import 'assets/images/rocket-only.svg';
import 'assets/images/decor.svg';
import 'assets/images/avatar-border.svg';
import 'assets/images/arbre.svg';
import 'assets/images/item-illustration.svg';
import 'assets/images/thumbs-up.png';
import 'assets/images/cloud.svg';
import 'react-circular-progressbar/dist/styles.css';
import { use } from 'utils/Translations';
import Translation from 'resources/translations';
import ES6Promise from "es6-promise";
import 'react-tippy/dist/tippy.css';
import 'babel-polyfill';

ES6Promise.polyfill();

var lang = window.location.hash.split('/');
var instanceAlias; 

if (lang.length >= 2) {
  instanceAlias = lang[1].length > 0 && lang[1] !== 'oAuth' ? lang[1] : 'fr'
} else {
  instanceAlias = 'fr' //default
}

Translation.getTranslations({instance: instanceAlias})
  .then(translations => {
    use(translations);

    ReactDOM.render(
      <App instance_alias={instanceAlias}/>,
      document.getElementById('root')
    );
  })
  .catch(err => console.log(err));

