import axios from '../config/axios';

const get = async () => 
  (await axios.get(`/translations`)).data;

const getTranslations = async (args) => 
  (await axios.get(`/translations/all/${args.instance}`, args)).data;

export default {
  get,
  getTranslations,
}
