import React, { Component } from 'react';
//organisms
import HeaderBack from 'components/organisms/Headers/HeaderBack';
//template
import LayoutSidebar from 'components/templates/sidebar';
// Utils
import { tl } from 'utils/Translations';

class AccessibilityCondition extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  };


  render() {
    return (
      <div style={{width: '75%', float: 'right'}}>
        <HeaderBack _onBackClick={() => { this.props.history.goBack() }} {...this.props} />
        <LayoutSidebar></LayoutSidebar>
        <div dangerouslySetInnerHTML={{ __html: tl('AccessibilityCondition') }}></div>
      </div >
    )
  }
}

export default AccessibilityCondition;
