import React, { Component } from 'react'

import styles from './style.scss';
import ChapterResource from 'resources/chapter';
import AuthResource from 'resources/auth';
import LinksResource from 'resources/links';

import Page from './page.js';


class Download extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    return AuthResource.getUser()
      .then((user) => {
        ChapterResource.getAllChapters()
          .then((chaptersResponse) => {
            LinksResource.getLinks()
              .then((links) => {
                return ChapterResource.getAllChaptersDiagnostics()
                  .then(diagnostics => {
                    diagnostics[0].selected = true;
                    _.orderBy(diagnostics, ['_id'], ['asc']);
                    this.setState({
                      chapters: diagnostics,
                      user: user,
                      links: links,
                    });
                  })
              })
          })
      })
  };


  render() {
    if (this.state.user && this.state.chapters) {
      let result = [];
      for (let i = 0; i < this.state.chapters.length; i = i + 4) {
        result.push(this.state.chapters.slice(i, i + 4))
      }
      return (
        <div className='download'>
          {
            _.map(result, (chapters, i) => 
              <div key={i}>
                <Page
                  user={this.state.user} chapters={chapters} links={this.state.links} displayBottom={chapters.length <= 2 ? true : false} />
              <div className="html2pdf__page-break"></div>
            </div>)
          }
          {
            this.state.chapters.length % 4 === 0 || this.state.chapters.length % 4 === 3 ?
              <div>
                <Page user={this.state.user} chapters={{}} links={this.state.links} displayBottom={true} />
                <div className="html2pdf__page-break"></div>
              </div>
              : ''
          }
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}

export default Download;