import React, { Component } from 'react'
import Chevron from 'assets/images/chevron.svg';
import './style.scss';

class Select extends Component {
  constructor(props){
    super(props);

    this.state = {
      openSelect : false,
      selectedItem: props.selectedOption
    };

    this.handleOptClick = this.handleOptClick.bind(this)
    this.displaySelect = this.displaySelect.bind(this)
  }

  handleOptClick(opt){
    this.setState({
      selectedItem: opt.label
    }, () => {
      this.props.handleSelect(opt)
    })
  }

  displaySelect(){
    let selectStatus = this.state.openSelect;
    this.setState({
      openSelect: !selectStatus
    })
  }

  render() {
    return (
      <div 
        className={`Dad-select ${this.props.className || ''} ${this.state.openSelect ? 'Dad-select--open': ''} `} 
        onClick={this.displaySelect}>
          <div className="Dad-select__value">
            <span className="Dad-select__label">
              {this.state.selectedItem}
            </span>
            <span className="Dad-select__icon">
              <img src={Chevron} alt="" />
            </span>
          </div>
          <ul className="Dad-select__options">
            {
              this.props.options.map((option, i) =>
              (
                <li 
                  key={`opt-${i}`} 
                  className={`Dad-select__option ${this.props.optionClassName || ''}`} 
                  onClick={this.handleOptClick.bind(this,option)}>
                  {option.label}
                </li>
              ))
            }
          </ul>
      </div>
    )
  }
}

export default Select;
