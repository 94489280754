import React, { Component } from 'react';
import Button from 'components/atoms/Button';

import { tl } from 'utils/Translations';

import './style.scss';
import dadLogo from 'assets/images/dad-logo.svg';

class HeaderLanding extends Component {
  constructor(props) {
    super(props);
    this.state = { links : props.links,  width: window.innerWidth };
    this._handleSidnav = this._handleSidnav.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this._handleScroll = this._handleScroll.bind(this)
  }

  componentDidMount() {
    this._handleScroll()
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth });
  };

  _handleSidnav() {
    this.setState({ sidenav : !this.state.sidenav })
  }

  _handleClick(l) {
    this.setState({ sidenav : false }, () => {
      this.props._handleClick(l);
    })
  }

  _handleScroll (event) {
    const headerLanding = document.getElementById('Header');
    var prevScrollpos = window.pageYOffset;

    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        headerLanding.classList.remove('Dad-header--sticky');
      } else {
        headerLanding.classList.add('Dad-header--sticky');
      }
      prevScrollpos = currentScrollPos;
    }    
  }


  render() {
    if(!this.state) return;

    const { width } = this.state;
    const isMobile = width <= 768;
    let _this = this;

    let styles = {}

    if(this.props.backgroundColor !== null){
      styles['background'] = this.props.backgroundColor
    }

    if(this.props.backgroundImage !== null){
      styles['backgroundImage'] = `url(${this.props.backgroundImage})`;
      styles['backgroundSize'] = "cover";
      styles["backgroundRepeat"] = "no-repeat";
    }
    return (
      <header className="Dad-header" id="Header" style={styles}>
        <div className="Dad-header__container">
          <div className="Dad-header__brand">
            <img src={dadLogo} alt="Orange"/>
          </div>

          <nav className="Dad-header__Button col-6 col-md-3">
            {this.props.children}
          </nav>
        </div>
      </header>
    )
  }
}
export default HeaderLanding;
