import React, { Component } from 'react'
import './style.scss';


class Spinner extends Component {
  constructor(props){
    super(props)
  }

  render () {
    return (
      <div className="Spinner"></div>
    )
  }
}


export default Spinner;