import React, { Component } from 'react'
import { Animated } from "react-animated-css";
import { Tooltip } from 'react-tippy';
import './style.scss'

// Atoms
// import SmileyIcon from 'components/atoms/SmileyIcon';
import Spinner from 'components/atoms/Spinner';
import Button from 'components/atoms/Button';

// Molecules
import ProgressBar from 'components/molecules/ProgressBar';


// Organisms 

// Factories
import AnswersFactory from 'resources/answers';

// Utils
import { tl } from 'utils/Translations';


class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chapter: this.props.chapter,
      currentQuestion: this.props.currentQuestion,
      isDone: this.props.isDone,
      width: window.innerWidth,
      loading: false // Show spinner during loading ...
    };
    this.refresh = this.props.refresh;
    this.onHover = this.onHover.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.onLeaveContainer = this.onLeaveContainer.bind(this);
    this.selectAnswer = this.selectAnswer.bind(this);
    this._handleQuestionNavigation = this._handleQuestionNavigation.bind(this);
    this._handleClickFinish = this._handleClickFinish.bind(this);
    this.handlePrevQuestionClick = this.handlePrevQuestionClick.bind(this)
    this.handleNextQuestionClick = this.handleNextQuestionClick.bind(this)
    this.onResize = this.onResize.bind(this);
  }

  componentWillUpdate() {
    this.onResize
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.chapter != prevState.chapter_id ||
      nextProps.currentQuestion != prevState.currentQuestion ||
      nextProps.isDone != prevState.isDone) {
      return {
        chapter: nextProps.chapter,
        currentQuestion: nextProps.currentQuestion,
        isDone: nextProps.isDone,
        loading: false
      };
    }
    return null;
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onHover(i) {
    let answers = document.getElementsByClassName('Dad-DS__body-answers__item');
    Array.from(answers).map((a, j) => {
      if (j <= i) a.classList.add('Dad-DS__body-answers__item--hovered');
    });
  };

  onLeave() {
    let answers = document.getElementsByClassName('Dad-DS__body-answers__item');
    Array.from(answers).map((a, i) => {
      a.classList.remove('Dad-DS__body-answers__item--hovered');
    });
  };

  onLeaveContainer() {


    let answers = document.getElementsByClassName('Dad-DS__body-answers__item');
    const selectedAnswer = this.state.currentQuestion.answers.findIndex(a => a.selected);

    Array.from(answers).map((a, i) => {
      if (i <= selectedAnswer) a.classList.add('Dad-DS__body-answers__item--hovered');
    });
  }

  onResize() {
    this.setState({
      width: window.innerWidth
    })
  }

  selectAnswer(answer, i) {
    this.setState({
      loading: true
    }, () => {
      return AnswersFactory.answerQuestion({
        chapterId: this.state.chapter._id,
        questionId: this.state.currentQuestion._id,
        setId: this.state.currentQuestion.set_id,
        answerId: answer._id,
        index: i
      })
        .then((answerResponse) => {
          if (answerResponse.data === 'DONE') {
            return AnswersFactory.updateCurrentChapter(this.state.chapter._id, this.state.currentQuestion._id)
              .then(() => {
                this.setState({
                  isDone: true,
                  loading: false
                }, () => {
                  this.refresh();
                })
              })
              .catch((updateChapterRespErr) => console.error(updateChapterRespErr))
          } else {
            return AnswersFactory.updateCurrentChapter(this.state.chapter._id, this.state.currentQuestion._id)
              .then(() => {
                this.refresh();
              })
              .catch((updateChapterRespErr) => console.error(updateChapterRespErr))
          }
        })
        .catch((answerQuestionError) => {
          console.error('here', answerQuestionError)
          this.props.history.push('/fr');
        })
    })
  };

  _handleQuestionNavigation(question) {

    return AnswersFactory.getPreviousAnswer({
      chapter_id: this.state.chapter._id,
      question_id: question
    })
      .then((results) => {
        this.setState({ chapter: results.data.chapter, currentQuestion: results.data.currentQuestion });
      })
  };

  _handleClickFinish() {
    return AnswersFactory.completeCurrentChapter(this.state.chapter._id)
      .then(() => {
        this.props._terminateDS()
      })
  }

  handlePrevQuestionClick(e) {

    //find question index
    let qIndex = this.state.currentQuestion.nbQuestions.findIndex(q => q == this.state.currentQuestion._id);

    if (qIndex !== 0) qIndex -= 1; //retourne à la question précédente

    return AnswersFactory.getPreviousAnswer({
      chapter_id: this.state.chapter._id,
      question_id: this.state.currentQuestion.nbQuestions[qIndex]
    })
      .then((results) => {
        this.setState({ chapter: results.data.chapter, currentQuestion: results.data.currentQuestion });
      })
  }

  handleNextQuestionClick(e) {

    //find question index
    let qIndex = this.state.currentQuestion.nbQuestions.findIndex(q => q == this.state.currentQuestion._id);

    if (qIndex < this.state.currentQuestion.nbQuestions.length - 1) qIndex += 1; //retourne à la question précédente

    if (qIndex - 1 >= this.state.currentQuestion.userAnsweredQuestions.length) qIndex -= 1;

    return AnswersFactory.getPreviousAnswer({
      chapter_id: this.state.chapter._id,
      question_id: this.state.currentQuestion.nbQuestions[qIndex]
    })
      .then((results) => {
        this.setState({ chapter: results.data.chapter, currentQuestion: results.data.currentQuestion });
      })
  }



  render() {
    const isMobile = this.state.width <= 768;


    if (this.state && this.state.loading) {
      return <Spinner />
    } else {
      return (
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationInDelay={150}
          animationOutDelay={200}
          className={`${this.props.className || 'Dad-DS col-md-10 offset-md-1'}`}
          isVisible={true}>
          {/* HEAD */}
          <div className="Dad-DS__head row no-gutters">

            {/* Navigation */}
            <nav className="Dad-DS-navigator col-md-4">
              <span className="Dad-DS-navigator__prev" onClick={this.handlePrevQuestionClick}></span>
              <ul className="Dad-DS-navigator__container">
                {
                  this.state.currentQuestion.nbQuestions.map((q, i) => {
                    if (q == this.state.currentQuestion._id) {
                      if (this.state.currentQuestion.userAnsweredQuestions.findIndex(r => r == q) != -1) {
                        return (
                          <li className="Dad-DS-navigator__item" key={i}>
                            <p className="Dad-DS-navigator__label">
                              {this.state.currentQuestion.nbQuestions.findIndex(q => q == this.state.currentQuestion._id) + 1}/{this.state.currentQuestion.nbQuestions.length
                              }
                            </p>
                            <span className="Dad-DS-navigator__check Dad-DS-navigator__check--current Dad-DS-navigator__check--checked"></span>
                          </li>
                        )
                      } else {
                        return (
                          <li className="Dad-DS-navigator__item" key={i}>
                            <p className="Dad-DS-navigator__label"> {this.state.currentQuestion.nbQuestions.findIndex(q => q == this.state.currentQuestion._id) + 1}/{this.state.currentQuestion.nbQuestions.length}</p>
                            <span className="Dad-DS-navigator__check Dad-DS-navigator__check--current"></span>
                          </li>
                        )
                      }
                    } else {
                      if (this.state.currentQuestion.userAnsweredQuestions.findIndex(r => r == q) != -1) {
                        return <li className="Dad-DS-navigator__item Dad-DS-navigator__item--checked" key={i} onClick={() => this._handleQuestionNavigation(q)}>
                          <span className="Dad-DS-navigator__check Dad-DS-navigator__check--checked"></span>
                        </li>
                      } else {
                        return <li className="Dad-DS-navigator__item" key={i} onClick={() => this._handleQuestionNavigation(q)}>
                          <span className="Dad-DS-navigator__check"></span>
                        </li>
                      }
                    }
                  })
                }
              </ul>
              <span className="Dad-DS-navigator__next" onClick={this.handleNextQuestionClick}></span>
            </nav>
            {/* Progression  */}
            <div className="Dad-DS__progress col-md-4">
              {
                this.state.isDone ? (
                  <Animated
                    animationIn="slideInTop"
                    animationInDelay={150}
                    animationOutDelay={200}>
                    <Button className="primary" onClick={this._handleClickFinish}> {tl('MainPagesDadCOntentsChaptersItemFinish')} </Button>
                  </Animated>

                ) : (
                  <ProgressBar
                    width={`${this.state.currentQuestion.userAnsweredQuestions.length / this.state.currentQuestion.nbQuestions.length * 100}%`}
                    backgroundColor="rgba(214, 214, 214, 0.5)"
                    fillColor="#ff7900"
                  />
                )
              }
            </div>
          </div>


          {/* BODY */}

          <div className="Dad-DS__body">
            <p className="Dad-DS__body-question">{this.state.currentQuestion.label}</p>
            {/* ANSWERS*/}
            <ul className="Dad-DS__body-answers row no-gutters" onMouseLeave={this.onLeaveContainer}>
              {

                this.state.currentQuestion.answers.map((answer, i) => {
                  let index = this.state.currentQuestion.answers.findIndex(a => a.selected);
                  if (isMobile) {
                    return <li
                      key={i}
                      className={`
                        col-2
                        ${i == index ? 'Dad-DS__body-answers__item--checked' : ''}
                        ${i <= index ? 'Dad-DS__body-answers__item--hovered' : ''}
                        Dad-DS__body-answers__item
                      `}
                      onClick={() => this.selectAnswer(answer, i)}
                      onMouseLeave={() => { }}
                      onMouseOver={() => { }} >
                      <div className="Dad-DS__body-answers__image" style={{
                        backgroundImage: 'url(' + answer.imageURL + ')',
                        width: `${(5 + i) * 5}px`,
                        height: `${(5 + i) * 5}px`,
                      }}>
                      </div>
                      <span className="Dad-DS__body-answers__label">{i + 1}</span>
                    </li>
                  } else {
                    return <li
                      key={i}
                      className={`
                        ${i == index ? 'Dad-DS__body-answers__item--checked' : ''}
                        ${i <= index ? 'Dad-DS__body-answers__item--hovered' : ''}
                        Dad-DS__body-answers__item
                      `}
                      onClick={() => this.selectAnswer(answer, i)}
                      onMouseLeave={() => this.onLeave()}
                      onMouseOver={() => this.onHover(i)} >
                      <div className="Dad-DS__body-answers__image" style={{
                        backgroundImage: 'url(' + answer.imageURL + ')',
                        width: `${(11 + i) * 8}px`,
                        height: `${(11 + i) * 8}px`,
                      }}></div>
                      <span className="Dad-DS__body-answers__label">{i + 1}</span>
                    </li>
                  }

                })
              }
            </ul>
          </div>

          {/* HELPER */}
          <div className="Dad-DS__wrapper-trigger">

            <Tooltip
              position="top"
              trigger="click"
              distance={20}
              theme="light"
              arrow={true}
              unmountHTMLWhenHide={true}
              onShow={() => {
                document.querySelector('.Dad-DS__trigger-tooltip').classList.add('icon-close');
              }}
              onHide={() => {
                document.querySelector('.Dad-DS__trigger-tooltip').classList.remove('icon-close');
              }}
              html={(
                <div className="Dad-tooltip Dad-tooltip--DS">
                  <h2 className="Dad-tooltip__title">
                    {tl('QuestionHelpTranslation')}
                  </h2>
                  <p className="Dad-tooltip__description">
                    {this.state.currentQuestion.description}
                  </p>
                </div>
              )}>
              <div className="Dad-DS__trigger-tooltip">
                {/* <SmileyIcon fill="#FFFFFF" /> */}
              </div>
            </Tooltip>
          </div>
        </Animated>
      )
    }
  }
}

export default Questions;
