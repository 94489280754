import React, { Component} from 'react'
import PropTypes from 'prop-types';
import './style.scss';

class TimelineMobile extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="Dad-rocket-timeline--mobile">
        <div className="Dad-rocket-timeline__gauge">
          <div className="Dad-rocket-timeline__current" style={{
            width: `${this.props.progress}%`
          }}></div>
        </div>
      </div>
    )
  }
}

TimelineMobile.defaultProps = {
  progress: 0
}

TimelineMobile.propTypes = {
  progress: PropTypes.number
}

export default TimelineMobile;

