import React, { Component } from "react";
import "./style.scss";
import ContentFactory from "resources/contents";
import AuthFactory from "resources/auth";

// Organisms
import Loader from "components/organisms/Loader";
import HeaderBack from "components/organisms/Headers/HeaderBack";
import FormRegister from "components/organisms/Forms/Register";
import TimelineRocket from "components/organisms/Timeline/Rocket";
import TimelineMobile from "components/organisms/Timeline/Mobile";

// Templates
import LayoutSidebar from "components/templates/sidebar";
import LayoutMain from "components/templates/main";

// Utils
import Storage from "utils/Storage";
import { tl } from "utils/Translations";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userNameSelected: null,
      userDataDisabled: false,
      error: false,
      jobs: [],
      icons: [],
      regions: [],
      loading: true,
      instance_alias: props.match.params.instance_alias,
      progress: 0,
      nicknames: []
    };
  }
  
  //load all datas on register
  componentDidMount() {
    return AuthFactory.isAuthenticated()
      .then((loggedResponse) => {
        this.props.history.push(`${this.state.instance_alias}/chapters`);
      })
      .catch(() => {
        ContentFactory.getIcons({ instance_alias: this.state.instance_alias })
          .then((icons) => {
            ContentFactory.getJobs({
              instance_alias: this.state.instance_alias,
            })
              .then((jobs) => {
                ContentFactory.getRegions({
                  instance_alias: this.state.instance_alias,
                })
                  .then((regions) => {
                    const currentUserAfteroAuth = Storage.getItem({
                      key: "currentUserAfteroAuth",
                    });
                    const userAuth =
                      currentUserAfteroAuth && currentUserAfteroAuth !== ""
                        ? JSON.parse(currentUserAfteroAuth)
                        : null;
                    this.setState({
                      icons: icons.data,
                      jobs: jobs.data,
                      regions: regions.data,
                      loading: false,
                      userNameSelected: userAuth ? userAuth.nickname : null,
                      userDataDisabled: userAuth ? true : false,
                    });
                  })
                  .catch((regionsError) => console.error(regionsError));
              })
              .catch((jobsError) => console.error(jobsError));
          })
          .catch((iconsErr) => console.error(iconsErr));
      });
  }

  selectedNickName(args) {
    let error;
    if (args.obj.data.status === "OK") {
      error = args.obj.data.message;
    }
    this.setState({ userNameSelected: args.nickname, error: error });
  }

  async completeRegister(args) {
    try {
      const validationResult = await AuthFactory.getNickNameValidation(
        args.nickNameSelected, 
        this.props.match.params.instance_alias
      );

      if (validationResult.data.code === 'LOGIN_AVAILABLE') {
        await AuthFactory.register({
          nickname: args.nickNameSelected,
          ageRange: this.state.ageRange,
          instanceAlias: this.props.match.params.instance_alias
        });

        await AuthFactory.addUserInfos({
          user: args.nickNameSelected,
          job: args.jobSelected._id,
          region: args.regionSelected._id,
          avatar: args.avatarSelected._id,
          instance_alias: this.state.instance_alias,
        })

        await AuthFactory.login({
          nickname: args.nickNameSelected,
          instance: this.props.match.params.instance_alias
        })

        this.props.history.push(`/${this.state.instance_alias}/chapters`)
      } else {
        this.setState({
          nicknames: validationResult.data.message,
          userNameExists : true,
          errorMessage: tl('MainComponentsPagesRegisterNickNamePickUsername', this.state.username ),
          errorTitle: tl('MainComponentsPagesRegisterNickNamePickError')
        })
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <div>
        <p>{this.state.error ? this.state.error : null}</p>
        <HeaderBack
          _onBackClick={() => this.props.history.push(`/${this.state.instance_alias}/qualification`)}
          {...this.props}
        />
        {/* PROGRESS MOBILE */}
        <TimelineMobile progress={this.state.progress} />
        <LayoutSidebar>
          <div className="Dad-register-sidebar">
            <h1 className="Dad-register-sidebar__title">
              {tl("CreateProfileTranslation")}
            </h1>
            <p className="Dad-register-sidebar__description">
              {tl("CreateProfileDescriptionTranslation")}
            </p>
          </div>
        </LayoutSidebar>
        <LayoutMain>
          {/* FORM */}
          <div className="container">
            <FormRegister
              proposedNicknames={this.state.nicknames}
              errorMessage={this.state.errorMessage}
              completeRegister={(args) => this.completeRegister(args)}
              jobs={this.state.jobs}
              regions={this.state.regions}
              icons={this.state.icons}
              instanceAlias={this.state.instance_alias}
              userNameSelected={this.state.userNameSelected}
              userDataDisabled={this.state.userDataDisabled}
              userNameExists={this.state.userNameExists}
              _onProgress={(progress) => {
                this.setState({ progress });
              }}
            />
          </div>

          {/* TIMELINE */}
          <TimelineRocket progress={this.state.progress} />
        </LayoutMain>
      </div>
    );
  }
}

export default Register;
