import React, { Component } from 'react'

import Label from 'components/atoms/Label';
import Select from 'components/atoms/Select';


import style from './style.scss';
import { tl } from 'utils/Translations';

class Jobs extends Component {
  constructor(props) {
    super(props);

    if(props.selectedJob !== null){
      selectedJobLabel = _.find(props.jobs, {_id: props.selectedJob}).label;
    }
    this.state = {
      selectedJob : null
    }

    this.handleJobSelect = this.handleJobSelect.bind(this)
  }

  handleJobSelect(job){
    this.props.handleJobSelection(job);
  }


  render() {
    return (
      <div>
        <Label value={tl('MainOrganismsRegisterJobs')} />
        <Select 
          options={this.props.jobs} 
          selectedOption={this.state.selectedJob} 
          handleSelect={this.handleJobSelect}/>
      </div>
    )
    // const { width } = this.state;
    // const isMobile = width <= 768;
    // if (isMobile) {
    //   return (<div className="Jobs">
    //     <Title type="tertiary" outline={false} title={tl('MainOrganismsRegisterJobs')} />
    //     <div className="Jobs__container container">
    //       <p className="Jobs__container__explanations">{tl('MainOrganismsRegisterJobsExplanations')}</p>
    //       {/* <OwlCarousel
    //         className="owl-theme"
    //         dotClass="job-dot"
    //         items={1}
    //         stagePadding={35}
    //         margin={0}
    //       >
    //         {
    //           _.map(this.props.jobs, (job, i) => {
    //             return (<JobCard
    //               key={`job-${i}`}
    //               className="col-lg-2"
    //               job={job}
    //               handleClick={this.handleJobClick}
    //               selectedJob={this.state.selected_job_id} />)
    //           })
    //         }
    //       </OwlCarousel> */}
    //     </div>
    //   </div>)
    // }
    // else {
    //   return (
    //     <div className="Jobs">
    //       <Title type="tertiary" outline={false} title={tl('MainOrganismsRegisterJobs')} />
    //       <div className="Jobs__container container">
    //         <p className="Jobs__container__explanations">{tl('MainOrganismsRegisterJobsExplanations')}</p>
    //         <div className="Jobs__row row col-md-8 mx-auto">
    //           <div className="d-flex flex-row jobs-row justify-content-center">
    //             {
    //               this.props.jobs.slice(0, 3).map((job, i) =>
    //                 <JobCard
    //                   key={`job-${i}`}
    //                   className="col-lg-2"
    //                   job={job}
    //                   handleClick={this.handleJobClick}
    //                   selectedJob={this.state.selected_job_id} />
    //               )
    //             }
    //           </div>
    //         </div>
    //         <div className="Jobs__row row col-md-8 mx-auto">
    //           <div className="d-flex flex-row jobs-row justify-content-center">
    //             {
    //               this.props.jobs.slice(3, 6).map((job, i) =>
    //                 <JobCard
    //                   key={`job-${i * 8}`}
    //                   className="col-lg-2"
    //                   job={job}
    //                   handleClick={this.handleJobClick}
    //                   selectedJob={this.state.selected_job_id} />
    //               )
    //             }
    //           </div>
    //         </div>
    //       </div>
    //     </div>
  }
}

export default Jobs;
