import axios from '../config/axios';

const getAllChapters = async () =>
  (await axios.get(`/chapters/all`)).data;

const getChapterById = async (chapter_id) =>
  (await axios.get(`/chapters/item/${chapter_id}`)).data;

const getAllChaptersDiagnostics = async () =>
  (await axios.get(`/chapters/diagnostic`)).data;

export default {
  getAllChapters,
  getChapterById,
  getAllChaptersDiagnostics,
}
