import React, { Component } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import "./style.scss";

class ProgressBarRounded extends Component {
  constructor(props) {
    super(props);
    this.state = props;
    this.radialProgress = this.radialProgress.bind(this);
    this.refreshComponent = this.refreshComponent.bind(this);
  }

  radialProgress({
    selector,
    zoom,
    thickness,
    backgroundColor,
    fillColor,
    label,
  }) {
    zoom = Math.min(zoom, 1);
    const parent = d3.select(selector);
    parent.style("transform", `scale(${zoom})`);

    const size = parent.node().getBoundingClientRect();
    const svg = parent
      .append("svg")
      .attr("width", size.width)
      .attr("height", size.height);
    const outerRadius = Math.min(size.width, size.height) * 0.45;

    let value = 0;

    const mainArc = d3
      .arc()
      .startAngle(0)
      .endAngle(Math.PI * 2)
      .innerRadius(outerRadius - thickness)
      .outerRadius(outerRadius);

    svg
      .append("path")
      .attr("class", "progress-bar-bg")
      .attr("transform", `translate(${size.width / 2},${size.height / 2})`)
      .attr("d", mainArc())
      .attr("fill", backgroundColor);

    const mainArcPath = svg
      .append("path")
      .attr("class", "progress-bar")
      .attr("transform", `translate(${size.width / 2},${size.height / 2})`)
      .attr("fill", fillColor);

    svg
      .append("circle")
      .attr("class", "progress-bar")
      .attr(
        "transform",
        `translate(${size.width / 2},${size.height / 2 - outerRadius + thickness / 2
        })`
      )
      .attr("width", thickness)
      .attr("height", thickness)
      .attr("r", thickness / 2)
      .attr("fill", fillColor);

    const end = svg
      .append("circle")
      .attr("class", "progress-bar")
      .attr(
        "transform",
        `translate(${size.width / 2},${size.height / 2 - outerRadius + thickness / 2
        })`
      )
      .attr("width", thickness)
      .attr("height", thickness)
      .attr("r", thickness / 2)
      .attr("fill", fillColor);

    let percentLabel;
    if (label) {
      percentLabel = svg
        .append("text")
        .attr("class", "progress-label")
        // .attr('transform', `translate(${(size.width/2) - 20},${size.height/2})`)
        .attr(
          "transform",
          `translate(${size.width / 2 - (this.props.fromReport ? 5 : 20)},${size.height / 2
          })`
        )
        .text("0");
      let percentLabelOver = svg
        .append("text")
        .attr("class", "progress-label-fixed")
        .attr(
          "transform",
          `translate(${size.width / 2 + 30},${size.height / 2 + 10})`
        )
        .text("/ 5");
    }

    return {
      update: function (progressPercent) {
        const startValue = value;
        const startAngle = (Math.PI * startValue) / 50;
        const angleDiff = (Math.PI * progressPercent) / 50 - startAngle;
        const startAngleDeg = (startAngle / Math.PI) * 180;
        const angleDiffDeg = (angleDiff / Math.PI) * 180;
        const transitionDuration = 1500;

        mainArcPath
          .transition()
          .duration(transitionDuration)
          .attrTween("d", function () {
            return function (t) {
              mainArc.endAngle(startAngle + angleDiff * t);
              return mainArc();
            };
          });
        end
          .transition()
          .duration(transitionDuration)
          .attrTween("transform", function () {
            return function (t) {
              return (
                `translate(${size.width / 2},${size.height / 2})` +
                `rotate(${startAngleDeg + angleDiffDeg * t})` +
                `translate(0,-${outerRadius - thickness / 2})`
              );
            };
          });
        if (label) {
          percentLabel
            .transition()
            .duration(transitionDuration)
            .tween("bla", function () {
              return function (t) {
                percentLabel.text(
                  `${+(
                    (Math.round(
                      startValue + (progressPercent - startValue) * t
                    ) *
                      5) /
                    100
                  ).toFixed(1)}`
                );
              };
            });
        }
        value = progressPercent;
      },
    };
  }

  refreshComponent(nextProps) {
    this.setState(
      {
        ...nextProps,
      },
      () => {
        if (this.state.localCharts) {
          this.state.localCharts.map((c, i) =>
            c.update(this.state.charts[i].value)
          );
        } else {
          this.setState({
            localCharts: [...nextProps.charts].map((chart, i) => {
              let c = this.radialProgress({
                selector: `.${chart.selector}`,
                zoom: 1 - Math.abs(i - (nextProps.charts.length - 1)) * 0.15,
                thickness: chart.thickness,
                backgroundColor: chart.backgroundColor,
                fillColor: chart.fillColor,
                label: null,
              });
              c.update(chart.value);
              return c;
            }),
          });
        }
      }
    );
  }

  componentDidMount() {
    this.refreshComponent(this.props);
  }

  render() {
    return (
      <div className="ProgressBarRounded">
        {this.props.charts.map((c, i) => (
          <div className={c.selector} key={i} />
        ))}
      </div>
    );
  }
}

ProgressBarRounded.propTypes = {
  className: PropTypes.string,
  hasText: PropTypes.bool,
  charts: PropTypes.array,
};

export default ProgressBarRounded;
