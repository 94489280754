import React, { Component } from 'react'
import PropTypes from 'prop-types';

import IconUpload from 'assets/images/icon-upload.svg';
import './style.scss';

class Upload extends Component {
  constructor(props){
    super(props)
  }

  render () {
    var iconUpload = {
      backgroundImage: 'url(' + this.props.image + ')'
    }

    return (
      <div className="Dad-upload-image" >
        <div 
          className="Dad-upload-image__icon" 
          onClick={this.props.onClick}
          style={iconUpload}>
        </div>
      </div>
    )
  }
}

Upload.defaultProps = {
  image: IconUpload
}

Upload.propTypes = {
  onClick: PropTypes.func,
  image: PropTypes.string
}


export default Upload;

