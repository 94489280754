import React, { Component } from 'react'
import styles from './style.scss'
// Atoms
import PlayerIcon from 'components/atoms/PlayerIcon';
import Link from 'components/atoms/Link';
// Organisms 
import HeaderBack from 'components/organisms/Headers/HeaderBack';
import Loader from 'components/organisms/Loader';
import ModalVideo from 'components/organisms/Modal/Video';
import NavBar from 'components/organisms/NavBar';
// Templates
import LayoutSidebar from 'components/templates/sidebar';
import LayoutMain from 'components/templates/main';

import Questions from './Questions';
import Congratulations from './Congratulations';

// Factories
import ChapterFactory from 'resources/chapter';
import Auth from 'resources/auth';

// Utils
import { tl } from 'utils/Translations';
import { Animated } from 'react-animated-css';
import Storage from 'utils/Storage';
import _ from 'lodash';


class DS extends Component {
  constructor(props) {

    super(props);
    this.state = {
      loading: true,
      instance_alias: props.match.params.instance_alias,
      chapter_id: props.match.params.chapter_id,
      modal: {},
    };
    this.refresh = this.refresh.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.chapter_id != prevState.chapter_id) {
      return { chapter_id: nextProps.match.params.chapter_id, needRefresh: true };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.state.needRefresh) {
      this.setState({ needRefresh: false })
      this.refresh(this.state.chapter_id);
    }
  }

  openModal() {
    let vid = this.state.chapter.videoDescriptionURL ? document.getElementById('chapterVideo') : null;
    if (vid) vid.load(); //reload the video so the dom is refreshed    
    this.state.modal.show = true;
    this.setState(this.state);
  };

  closeModal() {
    let vid = this.state.chapter.videoDescriptionURL ? document.getElementById('chapterVideo') : null;
    if (vid) vid.load(); //reload the video so the dom is refreshed    
    this.state.modal.show = false;
    this.setState(this.state);
  };

  async refresh(id) {
    try {
      const user = await Auth.getUser();
      const chapters = await ChapterFactory.getAllChapters();
      const chapter = await ChapterFactory.getChapterById(id);
      let countCompleted = _.countBy(chapters, e => e.progress.status == 'DONE').true;

      if (!!user) {
        this.setState({ user });

        if (!chapter) {
          this.setState({
            loading: false,
            isCompleted: true,
            _diagnosticAvailability: countCompleted === chapters.length,
          });
        } else {
          let firstTime = this.state.user.chapterAnswers.find(c => c.chapter_id === chapter.chapter._id);
          if (!firstTime) {
            firstTime = _.find(this.state.user.currentChapters, { chapter_id: chapter.chapter._id }) === undefined;
          }

          //if not first time, try to get the session storage item
          if (!firstTime) {
            if (Storage.getItem({ key: `dad-chapter-${chapter.chapter._id}` }) === null) {
              Storage.setItem({ key: `dad-chapter-${chapter.chapter._id}`, value: 'stored' })
            }
          }

          if (firstTime === true) {
            Storage.setItem({ key: `dad-chapter-${chapter.chapter._id}`, value: 'stored' })
          }

          this.setState({
            ...chapter,
            loading: false,
            congratulations: this.props.location.search === '?result=true',
            hasNews: true,
            _diagnosticAvailability: countCompleted == chapters.length,
            chapter_id: this.props.match.params.chapter_id
          }, () => {
            if (firstTime === true) {
              setTimeout(() => this.openModal(), 200);
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };


  componentDidMount() {
    Auth.getUser()
      .then(user => {
        this.setState({ user }, () => {
          this.refresh(this.state.chapter_id);
        });
      })
      .catch(err => { console.log(err); console.log(this.props); this.props.history.push(`/${this.state.instance_alias}`) })
  };


  render() {
    if (this.state && this.state.loading) return <Loader />;
    else if (this.state && this.state.chapter && this.state.user) {
      return (
        <div>
          <HeaderBack
            {...this.props}
            user={this.state.user}
            _onBackClick={() => this.props.history.push(`/${this.props.match.params.instance_alias}/chapters`)}
          >
            <NavBar
              displayDiagnosticLink={this.state._diagnosticAvailability}
              instanceAlias={this.state.instance_alias}
              history={this.props.history}
            />
          </HeaderBack>

          <LayoutSidebar>
            <div className="Dad-DS-sidebar">
              <div className="Dad-DS-sidebar__icon"
                style={{ backgroundImage: 'url(' + this.state.chapter.iconURL + ')' }}>
              </div>
              <h1 className="Dad-DS-sidebar__title">
                {this.state.chapter.name}
              </h1>
              {
                this.state.chapter.timer ? (<section className="Dad-DS-sidebar__duration">
                  {this.state.chapter.timer}
                </section>) : null
              }
              <div className="Dad-DS-sidebar__video" onClick={this.openModal}>
                <PlayerIcon circleStroke="#FFFFFF" playerFill="#FFFFFF" />
                <span className="Dad-link--secondary">
                  {tl('VideoReviewTranslation')}
                </span>
              </div>
            </div>
          </LayoutSidebar>
          <LayoutMain>
            <div className="container">
              <div className="">
                {
                  !this.state.congratulations ? (
                    <Questions
                      refresh={() => this.refresh(this.state.chapter_id)}
                      _terminateDS={() => this.setState({ congratulations: true })}
                      chapter={this.state.chapter}
                      currentQuestion={this.state.currentQuestion}
                      isDone={this.state.isDone}
                    />
                  ) : (
                    <Animated
                      animationIn="fadeInUp"
                      animationOut="fadeOut"
                      className={`${this.props.className || ''}`}
                      isVisible={true}>
                      <Congratulations {...this.props} />
                    </Animated>
                  )
                }
              </div>
            </div>
          </LayoutMain>

          <ModalVideo
            title={this.state.chapter.name}
            description={this.state.chapter.description}
            open={this.state.modal.show}
            videoURL={this.state.chapter.videoDescriptionURL}
            poster={this.state.chapter.thumbnail}
            header={this.state.modal}
            _onClose={this.closeModal}
            closeFunction={this.closeModal} isClosable />

        </div>
      )
    } else {
      return null;
    }
  }
}

export default DS;
