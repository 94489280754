import React, { Component } from 'react'
import Title from 'components/molecules/Title';
import styles from './style.scss';

class Error extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: props.errorMessage || null,
      displayError: props.displayError || false,
      errorTitle: props.errorTitle || null
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.errorMessage !== prevState?.errorMessage ||
      nextProps?.displayError !== prevState?.displayError ||
      nextProps?.errorTitle !== prevState?.errorTitle) {
      return {
        errorMessage: nextProps.errorMessage,
        displayError: nextProps.displayError,
        errorTitle: nextProps.errorTitle
      };
    }
    return null;
  }

  render() {
    return (
      <div className={`Error ${this.props.className}`}>
        <div className="Error__content">
          <strong>{this.state.errorTitle}</strong>
          <p className="Error__content-message"> {this.state.errorMessage}</p>
        </div>
      </div>
    )
  }
}

export default Error;
