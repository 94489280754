import React, { Component } from "react";
import styles from "./style.scss";

// Atoms
import Button from "components/atoms/Button";
import Link from "components/atoms/Link";

// Molecules
import ProgressBar from "components/molecules/ProgressBar";
import ProgressBarRounded from "components/molecules/ProgressBarRounded";

// Organisms
import Loader from "components/organisms/Loader";

// Templates

// Factories
import ChapterFactory from "resources/chapter";
import InterludeFactory from "resources/interlude";
import Auth from "resources/auth";

// Utils
import { tl } from "utils/Translations";
import { Tooltip } from "react-tippy";

class Congratulations extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    try {
      const user = await Auth.getUser();
      const chapters = await ChapterFactory.getAllChapters();
      const interludes = await InterludeFactory.getRandomInterlude();
      const globalDiagnostics =
        await ChapterFactory.getAllChaptersDiagnostics();
      const diagnostic = globalDiagnostics.find(
        (gd) => gd._id === this.props.match.params.chapter_id
      );
      diagnostic.links =
        diagnostic.results[
          Math.min(
            Math.max(Math.floor(diagnostic.myStats) - 1, 0),
            diagnostic.results.length - 1
          )
        ].links;
      diagnostic.message =
        diagnostic.results[
          Math.min(
            Math.max(Math.floor(diagnostic.myStats) - 1, 0),
            diagnostic.results.length - 1
          )
        ].text;
      this.setState({ chapters, congratulation: interludes, diagnostic, user });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    if (!this.state || !this.state.congratulation || !this.state.chapters)
      return <Loader />;
    if (
      !this.state.chapters.filter((c) => c.progress.status != "DONE").length
    ) {
      return (
        <div className="Dad-Congratulations col-md-10 offset-md-1">
          <div className="Dad-Congratulations__header">
            <h1 className="Dad-Congratulations__title">
              {tl("BravoTranslation")}
            </h1>
            <h3 className="Dad-Congratulations__subtitle">
              {tl("ChaptersAllFinishedTranslation")}
            </h3>
          </div>
          <div className="Dad-Congratulations__body">
            <div className="Dad-Congratulations__body__left ">
              <img src={this.state.congratulation.imageDescriptionUrl} />
            </div>
            <div className="Dad-Congratulations__body__right ">
              <h1>{tl("HereYouAreTranslation")}</h1>
              <p> {tl("GoToDiagnosticTranslation")}</p>
              <Button
                className="primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(
                    `/${this.props.match.params.instance_alias}/diagnostic`
                  );
                }}
              >
                {tl("GetDiagnosticTranslation")}
              </Button>

              <Link
                className="Dad-link--secondary"
                value={tl("EndOfInterlude")}
                _blank={false}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(
                    `/${this.props.match.params.instance_alias}/chapters`
                  );
                }}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="Dad-Congratulations col-md-10 offset-md-1">
          <div className="Dad-Congratulations__header">
            <h1 className="Dad-Congratulations__title">
              {tl("BravoTranslation")}
            </h1>
            <h3 className="Dad-Congratulations__subtitle">
              {tl("ChaptersOneFinishedTranslation")}
            </h3>
          </div>
          <div className="Dad-Congratulations__body">
            <div className="result">
              <div className="note">
                <strong>{tl("InterludeYourResult")}</strong>
                <div>
                  <span className="start-note">
                    {(+this.state.diagnostic.myStats).toFixed(1)}
                  </span>
                  <span className="end-note">/5</span>
                </div>
                <Tooltip
                  position="bottom"
                  trigger="mouseenter"
                  distance={50}
                  offset="70, 0"
                  theme="light"
                  arrow={false}
                  unmountHTMLWhenHide={true}
                  html={
                    <div className="congrats-tooltip">
                      {this.state.diagnostic.message}
                    </div>
                  }
                >
                  <div className="interrogation-point">?</div>
                </Tooltip>
              </div>
              <div className="chart">
                <ProgressBarRounded
                  fromReport
                  charts={[
                    {
                      selector: `progress__0${this.props.index}`,
                      thickness: 20,
                      backgroundColor: "#FFF",
                      fillColor: "#000",
                      value: Math.floor(
                        ((+this.state.diagnostic.myStats || 0) * 100) / 5
                      ),
                      label: true,
                    },
                    {
                      selector: `progress__1${this.props.index}`,
                      thickness: 8,
                      backgroundColor: "#FFF",
                      fillColor: "#FF7900",
                      value: Math.floor(
                        ((this.state.diagnostic.jobStats || 0) * 100) / 5
                      ),
                    },
                    {
                      selector: `progress__2${this.props.index}`,
                      thickness: 8,
                      backgroundColor: "#FFF",
                      fillColor: "#999A99",
                      value: Math.floor(
                        ((this.state.diagnostic.locationStats || 0) * 100) / 5
                      ),
                    },
                  ]}
                />
              </div>
              <div className="DadReport__body__analytics__viz-legend col-md-4">
                <div className="legend-container">
                  <div style={{ backgroundColor: "#000" }}></div>
                  <span>{this.state.user.nickname}</span>
                  <small style={{ backgroundColor: "#000" }}>
                    {" "}
                    {`${this.state.diagnostic.myStats}/5`}
                  </small>
                </div>
                <div className="legend-container">
                  <div style={{ backgroundColor: "#FF7900" }}></div>
                  <span>{tl("DadReportJob")}</span>
                  <small style={{ backgroundColor: "#FF7900" }}>
                    {" "}
                    {`${this.state.diagnostic.jobStats}/5`}
                  </small>
                </div>
                <div className="legend-container">
                  <div style={{ backgroundColor: "#999A99" }}></div>
                  <span>{tl("DadReportLocation")}</span>
                  <small style={{ backgroundColor: "#999A99" }}>
                    {" "}
                    {`${this.state.diagnostic.locationStats}/5`}
                  </small>
                </div>
              </div>
            </div>
            <div className="go-further">
              <h4>{tl("GoingFurtherTranslation")} :</h4>
              <ul className="list-link">
                {this.state.diagnostic.links.map((l, i) => (
                  <li key={i}>
                    <Link value={l.label} href={l.url} _blank />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="Dad-Congratulations__body">
            <div className="Dad-Congratulations__body__left ">
              <img src={this.state.congratulation.imageDescriptionUrl} />
              {/* <p> { this.state.congratulation.label }</p> */}
            </div>
            <div className="Dad-Congratulations__body__right ">
              <h1>{tl("YouAreCloseTranslation")} </h1>
              <p>
                {" "}
                {tl("OnlyLeftTranslation")}{" "}
                {
                  this.state.chapters.filter((c) => c.progress.status != "DONE")
                    .length
                }{" "}
                {tl("ChapterTranslation")}
                {this.state.chapters.filter((c) => c.progress.status != "DONE")
                  .length > 1
                  ? "s"
                  : ""}{" "}
                {tl("BeforeGetDiagnosticTranslation")}
              </p>
              <div className="Dad-Congratulations__body__right__progress">
                <ProgressBar
                  className="rounded"
                  width={`${
                    (this.state.chapters.filter(
                      (c) => c.progress.status == "DONE"
                    ).length /
                      this.state.chapters.length) *
                    100
                  }%`}
                  backgroundColor="rgba(214, 214, 214, 0.5)"
                  fillColor="#ff7900"
                />
              </div>
            </div>
          </div>
          <div className="interlude-footer">
            <Link
              className="Dad-link--secondary"
              value={tl("EndOfInterlude")}
              _blank={false}
              href={`#/${this.props.match.params.instance_alias}/chapters`}
            />
            <Button
              className="primary"
              onClick={() => {
                this.props.history.push(
                  `/${this.props.match.params.instance_alias}/chapters/${
                    this.state.chapters.filter(
                      (c) => c.progress.status != "DONE"
                    )[0]._id
                  }`
                );
              }}
            >
              {tl("NextChapterTranslation")}
            </Button>
          </div>
        </div>
      );
    }
  }
}

export default Congratulations;
