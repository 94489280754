import axios from '../config/axios';

const login = async (args) => 
   await axios.post(`/users/login`, { nickname : args.nickname, instance: args.instance });

const isAuthenticated = async () => 
  await axios.get(`/users/islogged`);

const logout = async () => 
  await axios.get(`/users/logout`);

const getNickNameValidation = async (nickname,instance) => 
  await axios.get(`/users/validatenickname`, { params : { instance: instance, nickname: nickname }})

const register = async (args) => 
  await axios.post(`/users/signup`, { nickname: args.nickname, instanceAlias: args.instanceAlias });

const getUser = async () => 
  (await axios.get(`/users/info`)).data; 

const addUserInfos = async (args) => 
  await axios.post(`/users/addinfos`, { args });

const deleteUser = async (args) => 
  await axios.delete(`/users/delete`, { args });

const generateStateOauthPlazza = async () => 
  (await axios.get(`/oAuthPlazza/generateState`)).data;

const getTokenOauthPlazza = async (state, code, instance) => 
  (await axios.get(`/oAuthPlazza/getToken`, { params : { state: state, code: code, instance: instance }})).data;

export default {
  login,
  isAuthenticated,
  getNickNameValidation,
  register,
  logout,
  getUser,
  addUserInfos,
  deleteUser,
  generateStateOauthPlazza,
  getTokenOauthPlazza
}


