import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { tl } from 'utils/Translations';

import '../global.scss';
import './style.scss';

import Button from 'components/atoms/Button';
import ProgressBar from 'components/molecules/ProgressBar';


class HeroPlanning extends Component {
  constructor(props){
    super(props);
  }

  render () {

    let avatarStyle = {
      left: this.props.currentProgress + '%'
    }

    //if avatar exists then display it
    if(this.props.avatar !== null){
      avatarStyle.backgroundImage = `url(${this.props.avatar.path})`;
      avatarStyle.backgroundSize = "contain";
    }
    
    return (
      <section className="Dad-hero Dad-hero--planning">
        <div className="Dad-hero__container container">
          <div className="Dad-hero__content row">
            
            <div className="col-10 offset-1">
              <div className="Dad-hero__message Dad-hero__message--hello">
                {
                  
                  (this.props.chaptersCompleted === 0 ) && 
                    <h1>
                      {tl('HelloTranslation')} <strong className="Dad-highlight--orange">{this.props.plazzaName ? this.props.plazzaName : this.props.nickname},</strong><br/> 
                      {tl('ChapterStartMessageTranslation')}
                    </h1>
                }

                {
                  
                  (this.props.chaptersCompleted !== 0 && this.props.chaptersCompleted < this.props.chaptersLength ) && 
                    <h1>
                      {tl('HelloTranslation')} <strong className="Dad-highlight--orange">{this.props.plazzaName ? this.props.plazzaName : this.props.nickname},</strong><br/> 
                      {tl('ChapterLeftMessageTranslation')} <span className="Dad-hero__highlight">{this.props.chaptersLeft}</span> 
                      &nbsp;{tl('ChapterTranslation')}{this.props.chaptersLeft > 1 ? 's' : ''}.
                    </h1>
                }

                {
                  
                  (this.props.chaptersCompleted === this.props.chaptersLength) && 
                    <h1>
                      {tl('HelloTranslation')} <strong className="Dad-highlight--orange">{this.props.plazzaName ? this.props.plazzaName : this.props.nickname},</strong><br/> 
                      {tl('ChapterAllDoneMessageTranslation')}
                    </h1>
                }

                
              </div>
            </div>

            <div className="col-8 offset-2 col-md-4 offset-md-4 Dad-hero__progress-wrapper">
              <div className="Dad-hero__progress">
                <ProgressBar 
                  className="rounded"
                  height="6px"
                  width={this.props.currentProgress + '%'}
                  backgroundColor="rgba(214, 214, 214, 0.5)"
                  fillColor="#ff7900"
                />
                <div className="Dad-hero__avatar" style={avatarStyle}>
                </div>
              </div>
            </div>

            <div className="Dad-hero__wrapper-cta col-md-4 offset-md-4">
              <div className="Dad-hero__cta">
                {
                  (this.props.chaptersCompleted === 0 ) && 
                  <Button
                    className="primary"
                    onClick={this.props.startChapter }>
                    {tl('ChapterStrartTranslation')}
                  </Button>
                }

                {
                  (this.props.chaptersCompleted > 0 && this.props.chaptersCompleted < this.props.chaptersLength) && 
                  <Button
                    className="primary"
                    onClick={this.props.startChapter }>
                    {tl('ChapterNextTranslation')}
                  </Button>
                }

                {
                  (this.props.chaptersCompleted === this.props.chaptersLength) && 
                  <Button
                    className="primary"
                    onClick={this.props.goToReport }>
                    {tl('GetDiagnosticTranslation')}
                  </Button>
                }
                
              </div>
              <div className="Dad-hero__message Dad-hero__message--progress">
                {
                  (this.props.chaptersLeft !== 0) && 
                    <div>
                      {tl('OnlyLeftTranslation')} <strong className="Dad-highlight--underline">{ this.props.chaptersLeft } </strong>
                      {tl('ChapterTranslation')}{this.props.chaptersLeft > 1 ? 's' : ''} {tl('BeforeAccessDiagnosticTranslation')}
                    </div>
                }
              </div>
            </div>            
          </div>
        </div>
      </section>
    )
  }
}

HeroPlanning.propTypes = {
  nickname: PropTypes.string,
  plazzaName: PropTypes.string,
  avatar: PropTypes.object,
  chaptersLeft: PropTypes.number,
  chaptersCompleted: PropTypes.number,
  chaptersLength: PropTypes.number,
  currentProgress: PropTypes.number
}


export default HeroPlanning;
