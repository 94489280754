import React, { Component } from 'react';
import styles from './style.scss';

import Button from 'components/atoms/Button';
import Editorialllustration from 'assets/images/editorial-illustration.svg';
// import Title from 'components/molecules/Title';
// import HeroSvg from 'components/atoms/HeroSvg';
// import Subtitle from 'components/molecules/Subtitle';
import { tl } from 'utils/Translations';


class Editorial extends Component {
  constructor(props){
    super(props);
  }


  render () {
    return (
      <section className="Dad-Editorial">
        <div className="Dad-Editorial__wrapper container">
          <p className="Dad-Editorial__illustration col-xs-12 col-md-6 col-lg-4 offset-lg-2">
            <img  
              src={Editorialllustration} 
              alt="" />
          </p>
          <p className="Dad-Editorial___description col-md-6 col-lg-4">
            {tl('LandingPageExplonations')}
          </p>
        </div>
      </section>
    )
  }
}


export default Editorial
