import React, { Component } from 'react'
import style from './style.scss';

// Assets
import dadLogo from 'assets/images/dad-logo.svg';

class Loader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interlude: false
    }
  }

  render() {
    return (
      <div className="Loader">
        <img src={dadLogo} alt="Orange" />
      </div>
    )
  }
}
export default Loader;
