import React, { Component } from 'react'
import styles from './style.scss'
// Atoms
import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';
// Organisms
import HeaderBack from 'components/organisms/Headers/HeaderBack';
import Modal from 'components/organisms/Modal';
// Templates
import LayoutSidebar from 'components/templates/sidebar';
import LayoutMain from 'components/templates/main';
// Utils
import { tl } from 'utils/Translations';
import AuthFactory from 'resources/auth'
class CGU extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    }
    this.deleteUser = this.deleteUser.bind(this)
    this.setIsOpen = this.setIsOpen.bind(this)
  };

  setIsOpen(option) {
    this.setState({ open: option });
  };

  async deleteUser() {
    try {
      const user = await AuthFactory.getUser();
      await AuthFactory.deleteUser(user);
      this.props.history.push(`/${this.props.match.params.instance_alias}`);
    } catch (e) {
      console.log(e);
    }

  }


  render() {
    return (
      <React.Fragment>
        <Modal
          open={this.state.modal}
          _onClose={() => this.setState({ modal: false })}
          isClosable
          modalClass='Modal Modal--disconnection'
        >
          <div className="container">
            <div className="col-md-4 offset-md-4 Modal--disconnection--container">
              <div className="Modal--disconnection--container-body">
                <h1> {tl('DeleteAccountTitleTranslation')} </h1>
                <span>{tl('DeleteAccountDescriptionTranslation')}</span>
              </div>
              <div className="Modal--disconnection--container-footer">
                <Link className="Dad-link--secondary" value={tl('GoBack')} onClick={() => this.setState({ modal: false })} />
                <Button
                  className="primary"
                  type="primary"
                  onClick={this.deleteUser}>
                  {tl('ConfirmDeletionTranslation')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <HeaderBack _onBackClick={() => { this.props.history.goBack() }} {...this.props} />
        <LayoutSidebar></LayoutSidebar>
        <LayoutMain className="Dad-layout-main-no-padding">
          <div className="container">
            <div className="col-md-10 offset-md-1 cgu-container" dangerouslySetInnerHTML={{ __html: tl('DadCGURawHTML') }}></div>
            <Button className="third" onClick={() => { this.setState({ modal: true }, () => this.setIsOpen(true)); }} > <i className="fas fa-trash-alt" /> {tl('DeleteAccountTranslation')} </Button>
          </div>
        </LayoutMain>
      </React.Fragment>
    )
  }
}

export default CGU;
