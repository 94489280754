import React, { Component } from 'react'
import PropTypes from 'prop-types';
import './style.scss';

class Link extends Component {
  constructor(props){
    super(props)
  }

  render () {
    return (
      <a 
        className={`Dad-link ${this.props.className || ''}`}
        onClick={ this.props.onClick ? this.props.onClick : null } 
        target={ this.props._blank ? '_blank' : ''} 
        href={this.props.href || ''}>
        { this.props.value }
      </a>
    )
  }
}


Link.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  onClick : PropTypes.func,
  _blank : PropTypes.bool
}


export default Link;
