import React, { Component } from "react";
import Instance from '../../../resources/instance';

import { getLanguage } from '../../../utils/Translations';

import Button from 'components/atoms/Button';

import "./style.scss";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ttInstance: [],
      selectedInstance: null
    }
    this.validate = this.validate.bind(this);
  };

  componentDidMount() {
    Instance.getAll().then(res => {
      this.setState({ ttInstance: res });
    });
  }

  selectInstance(event, instance) {
    let ttInstance = document.getElementsByClassName('selected');
    for (let i = 0; i < ttInstance.length; i++) {
      ttInstance[i].classList.remove('selected');
    }
    event.target.classList.add('selected');
    this.setState({ selectedInstance: instance });
  }

  validate() {
    this.props.history.push(`${this.state.selectedInstance.alias}`);
  }

  render() {
    return (
      <div className="home-page">
        <header className="home-header">
          <img id="logo-orange" src="assets/images/dad-logo.svg" alt="logo orange" />
        </header>
        <div className="content">
          <h1 className="home-title">Titre</h1>
          <h2 className="home-title">
            <em>Titre</em>
          </h2>
          <div className="instance-container">
            {
              this.state.ttInstance.map((instance, i) =>
              (
                <div
                  key={`opt-${i}`}
                  className="instance"
                  onClick={event => { this.selectInstance(event, instance) }}>
                  {instance.label}
                </div>
              ))
            }
          </div>
          <div className="button-container">
            <Button
              onClick={this.validate.bind()}
              className="primary"
              disabled={!this.state.selectedInstance}
            >
              Validate
            </Button>
          </div>
        </div>
        <img id="home-background" src="assets/images/hero-illustration.svg" />
        <div className="background-container"></div>
      </div>
    )
  }
}

export default Home;


