import React, { Component } from "react";
import { Redirect } from "react-router";

// components
import Loader from 'components/organisms/Loader';

// Resources
import AuthFactory from "resources/auth";

// Utils
import Storage from "utils/Storage";

class OAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectUrl: null
    }
  }

  componentDidMount() {
    const retourOauth = this.props.match.params.oauth_name;
    if (retourOauth && retourOauth !== "") {
      const codeOauth = this.getUrlParameter("code");
      const stateOauth = this.getUrlParameter("state");
      const instanceAlias = Storage.getItem({
        key: "instanceAliasBeforeOauth",
      });
      AuthFactory.getTokenOauthPlazza(
        stateOauth,
        codeOauth,
        instanceAlias
      ).then((result) => {
        if (!(result && result.nickname)) {
          this.setState({
            redirectUrl: '/'
          });
        } else {
          if (result.registerComplete) {
            AuthFactory.login({
              nickname: result.nickname,
              instance: instanceAlias,
            })
              .then(() => {
                this.setState({
                  redirectUrl: `/${instanceAlias}/chapters`
                });
              })
              .catch((loggedUserError) => {
                console.error(loggedUserError);
              });
          } else {
            Storage.setItem({
              key: `currentUserAfteroAuth`,
              value: JSON.stringify(result),
            });
            this.setState({
              redirectUrl: `/${instanceAlias}/register`
            });
          }
        }
      });
    }
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(this.props.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  render() {
    if (this.state.redirectUrl) {
      return <Redirect to={`${this.state.redirectUrl}`} />;
    }
    return (
      <Loader />
    );
  }
}

export default OAuth;
