import React, { Component} from 'react'
import { tl } from 'utils/Translations';

class Profile extends Component {
  constructor(props){
    super(props)
  }

  render () {
    return (
      <div>
        <span>{tl('MainPagesProfile')}</span>
      </div>
    )
  }
}

export default Profile;
