import React, { Component } from "react";

import style from "./style.scss";

import LinksFactory from "resources/links";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linksCategory: null,
      baseUrl: null,
      instance_id: null,
    };
  }

  componentDidMount() {
    let baseUrl = this.props.match.url.split("/")[1];
    return LinksFactory.getFooterLinks(this.props.instance_id).then((linksCategory) => {
      this.setState({ linksCategory, baseUrl });
    });
  }

  render() {
    if (this.state.linksCategory && this.state.linksCategory.length) {
      return (
        <div div className={`footer`}>
          <div className="link-container">
            {this.state.linksCategory && this.state.linksCategory.length
              ? this.state.linksCategory.map((category, i) => (
                  <div key={`opt-${i}`} className={`footer-category`}>
                    <h4>{category.categoryName}</h4>
                    {category.ttLink.map((link, j) => (
                      <a href={link.link} key={`opt-${j}`}>
                        {link.label}
                      </a>
                    ))}
                  </div>
                ))
              : ""}
          </div>
          <div className="subfooter">
            1988 - {new Date().getFullYear()} ©Orange
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}

export default Footer;
