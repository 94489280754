import React, { Component } from 'react'

import './style.page.scss';

import dadLogo from 'assets/images/dad-logo.svg';
import { tl } from 'utils/Translations';

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {...props};    
  }

  render() {
    const date = new Date();
    if(this.state && this.state.chapters && this.state.user) {
      return (
        <div className="Dad-download-page download-container">
          <div className="Dad-download-page__header container">
            <img src={dadLogo} />
            <h1>{ tl('MyDiagnosticTranslation')}</h1>
            <div>
              <span>{ this.state.user.nickname }</span>
              <small>{date.toLocaleDateString('fr-FR')}</small>
            </div>
          </div>
          <div className="Dad-download-page__hero"></div>
          <div className="container">
            {
              Array.isArray(this.state.chapters) && this.state.chapters.map((c, i) => {
                return (
                  <div className="col-md-5 Dad-download-page__chapter" key={i}>
                    <img src={ c.iconURL } />
                    <h2>{ c.name }</h2>
                    <div className="Dad-download-page__chapter-note">
                      <strong>{ Math.floor(c.myStats || 0) }</strong>
                      <span>/5</span>
                    </div>
                    <span>
                      {tl(`DadOrientationOrientationObservation_${Math.floor((c.myStats || 0))}`) }
                    </span>
                    <div className="Dad-download-page__chapter-footer">
                      <div className="Dad-download-page__chapter-footer-left">
                        < strong > { tl('MyJobTranslation') }</strong>
                        <div>
                          <strong>{ Math.floor(c.jobStats || 0) }</strong>
                          <span>/5</span>
                        </div>
                      </div>
                      <div className="Dad-download-page__chapter-footer-left">
                        <strong>{ tl('MyLocationTranslation')}</strong>
                        <div>
                          <strong>{ Math.floor(c.locationStats || 0) }</strong>
                          <span>/5</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            {
              this.state.displayBottom && (<div className="col-md-12 Dad-download-page__links">
                <h1>{tl('DadOrientationWhatToDoAfterDad')}</h1>
                <p>{tl('DadOrientationGoingFurther')}</p>
                <div className="Dad-download-page__links-container">
                  {
                    this.state.links.map((l,i) => {
                      return (
                        <div className="" key={i}>
                          <span>{ l.label }</span>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              )
            }
          </div>
        </div>
      )
    } 
    else return null;
  }
}

export default Page;