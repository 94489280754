import axios from '../config/axios';

const track = async (instance_alias) => 
  await axios.get(`/tracking/${instance_alias}`);

const trackLinkClicked = async (args) => 
  await axios.post(`/tracking/link`, args);

export default {
  track,
  trackLinkClicked
}
