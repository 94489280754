import React, { Component } from 'react'
import PropTypes from 'prop-types';


class IconChapter extends Component {
  constructor(props){
    super(props)
  }

  render () {
    return (
      <svg width="20px" height="19px" viewBox="0 0 20 19" className="Icon">
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Header" transform="translate(-638.000000, -30.000000)" fill={this.props.fill}>
                  <g id="Group-3" transform="translate(638.000000, 28.000000)">
                      <g id="Group-2" transform="translate(0.000000, 2.000000)">
                          <rect id="Rectangle-9" x="0" y="0" width="5.06989264" height="5"></rect>
                          <rect id="Rectangle-9-Copy-3" x="0" y="7" width="5.06989264" height="5"></rect>
                          <rect id="Rectangle-9-Copy" x="7.09784969" y="0" width="5.06989264" height="5"></rect>
                          <rect id="Rectangle-9-Copy-4" x="7.09784969" y="7" width="5.06989264" height="5"></rect>
                          <rect id="Rectangle-9-Copy-2" x="14.1956994" y="0" width="5.06989264" height="5"></rect>
                          <rect id="Rectangle-9-Copy-5" x="14.1956994" y="7" width="5.06989264" height="5"></rect>
                          <rect id="Rectangle-9-Copy-8" x="0" y="14" width="5.06989264" height="5"></rect>
                          <rect id="Rectangle-9-Copy-7" x="7.09784969" y="14" width="5.06989264" height="5"></rect>
                          <rect id="Rectangle-9-Copy-6" x="14.1956994" y="14" width="5.06989264" height="5"></rect>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    )
  }
}

IconChapter.propTypes = {
  fill: PropTypes.string
}


export default IconChapter;
