import React, { Component } from 'react'
import PropTypes from 'prop-types';

import './style.scss';

class PlayerIcon extends Component {
  constructor(props){
    super(props);
  }

  render () {
    return (
      <React.Fragment>
        <svg width="42px" height="42px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className={ this.props.className }>
            <g id="Devoir-surveillé" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="[Desktop]-DS" transform="translate(-140.000000, -637.000000)" fillRule="nonzero">
                    <g id="Group-2" transform="translate(141.000000, 638.000000)">
                        <g id="Group">
                            <g id="Group-4">
                                <polygon id="Path" fill={ this.props.playerFill } points="16 14 16 27 26 20.5"></polygon>
                                <circle id="Oval" stroke={ this.props.circleStroke } cx="20" cy="20" r="20"></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </React.Fragment>
    )
  }
}

PlayerIcon.propTypes = {
    className: PropTypes.string,
    circleStroke: PropTypes.string,
    playerFill: PropTypes.string,
};

export default PlayerIcon;
