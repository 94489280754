import React, { Component } from 'react'
import PropTypes from 'prop-types';
import './style.scss';

class Checkbox extends Component {
  constructor(props){
    super(props)
  }

  render () {
    return (
      <div className={ `Dad-Checkbox ${ this.props.className }`}>
        <svg width="9px" height="7px" viewBox="0 0 9 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g id="Emploi-du-temps" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="[Desktop]-Emploi-du-temps" transform="translate(-1151.000000, -600.000000)" fill={ this.props.fill }>
                    <g id="Group-6" transform="translate(1127.000000, 574.000000)">
                        <g id="Group-8" transform="translate(20.000000, 21.000000)">
                            <path d="M7.39706198,11.1835441 C7.22567282,11.1839115 7.06120267,11.1122845 6.94000984,10.9844972 L4.19769705,8.09218194 C4.02953289,7.92115707 3.96196788,7.66776309 4.02097025,7.42939057 C4.07997262,7.19101805 4.25636041,7.00476256 4.48233995,6.94221038 C4.7083195,6.87965819 4.94872381,6.95054368 5.11115484,7.12762241 L7.39641551,9.53731707 L11.5111776,5.19986668 C11.7636006,4.93351093 12.1730037,4.93335834 12.4256051,5.19952586 C12.6782066,5.46569338 12.6783513,5.89738879 12.4259284,6.16374455 L7.85411411,10.9844972 C7.73292128,11.1122845 7.56845113,11.1839115 7.39706198,11.1835441 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </div>
    )
  }
}

Checkbox.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
}


export default Checkbox;
