import React, { Component } from "react";
import { Router, Route, Switch } from "react-router";
import { createHashHistory as createHistory } from "history";
import Landing from "components/pages/Landing";
import Qualification from "components/pages/Qualification";
import Register from "components/pages/Register";
import Chapters from "components/pages/DAD/Chapters";
import DS from "components/pages/DAD/DS";
import Report from "components/pages/DAD/Report";
import Profile from "components/pages/Profile";
import CGU from "components/pages/CGU";
import AccessibilityCondition from "components/pages/AccessibilityCondition";
import OAuth from "components/pages/OAuth";
import Home from "components/pages/Home";
import "./style.scss";

const history = createHistory();
class App extends Component {

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/:instance_alias" component={Landing} />
          <Route exact path="/:instance_alias/qualification" component={Qualification} />  
          <Route exact path="/:instance_alias/register" component={Register} />
          <Route exact path="/:instance_alias/chapters" component={Chapters} />
          <Route path="/:instance_alias/chapters/:chapter_id" component={DS} />
          <Route exact path="/:instance_alias/diagnostic" component={Report} />
          <Route exact path="/:instance_alias/profile" component={Profile} />
          <Route exact path="/:instance_alias/cgu" component={CGU} />
          <Route exact path="/:instance_alias/accessibility" component={AccessibilityCondition} />
          <Route exact path="/oAuth/:oauth_name" component={OAuth} />
          <Route component={Home} />
        </Switch>
      </Router>
    );
  }
}
export default App;
