import React, { Component } from "react";
import styles from "./style.scss";
import PropTypes from "prop-types";
// Atoms
import Link from "components/atoms/Link";
// Utils
import { tl } from "utils/Translations";
import ProgressBarRounded from "../ProgressBarRounded";
import LinkIcon from "../../atoms/Link";
import Undo from "../../atoms/Undo";
import { Tooltip } from "react-tippy";
class OrientationCard extends Component {
  constructor(props) {
    super(props);

    this.goToSpecificChapter = this.goToSpecificChapter.bind(this);
  }

  goToSpecificChapter() {
    this.props.history.push(`chapters/${this.props.chapter._id}`);
  }

  render() {
    return (
      <div className={`OrientationCard ${this.props.className}`}>
        {/* Header */}
        <div className="OrientationCard__header">
          <span className="OrientationCard__header-myRate">
            {Math.floor(this.props.chapter.myStats).toFixed(1)}
          </span>
          <span className="OrientationCard__header-over">/5</span>
        </div>
        {/* Body */}
        <div className="OrientationCard__body">
          <h3>{this.props.chapter.name}</h3>
          <p>{this.props.message}</p>
        </div>
        <div className="DadReport__body__analytics row">
          <div className="DadReport__body__analytics__viz">
            <div className=" DadReport__body__analytics__viz-viz row">
              <div className="DadReport__body__analytics__viz-viz--progress col-md-8">
                <ProgressBarRounded
                  fromReport
                  charts={[
                    {
                      selector: `progress__0${this.props.index}`,
                      thickness: 20,
                      backgroundColor: "#FFF",
                      fillColor: "#000",
                      value: Math.floor(
                        ((this.props.chapter.myStats || 0) * 100) / 5
                      ),
                      label: true,
                    },
                    {
                      selector: `progress__1${this.props.index}`,
                      thickness: 8,
                      backgroundColor: "#FFF",
                      fillColor: "#FF7900",
                      value: Math.floor(
                        ((this.props.chapter.jobStats || 0) * 100) / 5
                      ),
                    },
                    {
                      selector: `progress__2${this.props.index}`,
                      thickness: 8,
                      backgroundColor: "#FFF",
                      fillColor: "#999A99",
                      value: Math.floor(
                        ((this.props.chapter.locationStats || 0) * 100) / 5
                      ),
                    },
                  ]}
                />
              </div>
              <div className="DadReport__body__analytics__viz-legend col-md-4">
                <div className="legend-container">
                  <div style={{ backgroundColor: "#000" }}></div>
                  <span>{this.props.user.nickname}</span>
                  <small style={{ backgroundColor: "#000" }}>
                    {" "}
                    {`${this.props.chapter.myStats}/5`}
                  </small>
                </div>
                <div className="legend-container">
                  <div style={{ backgroundColor: "#FF7900" }}></div>
                  <span>{tl("DadReportJob")}</span>
                  <small style={{ backgroundColor: "#FF7900" }}>
                    {" "}
                    {`${this.props.chapter.jobStats}/5`}
                  </small>
                </div>
                <div className="legend-container">
                  <div style={{ backgroundColor: "#999A99" }}></div>
                  <span>{tl("DadReportLocation")}</span>
                  <small style={{ backgroundColor: "#999A99" }}>
                    {" "}
                    {`${this.props.chapter.locationStats}/5`}
                  </small>
                </div>
              </div>
              <div className="DadReport__body__analytics__viz-viz--description">
                <p
                  className="Dad-link"
                  component={<Undo className="LinkIcon--component" />}
                  value={tl("RedoChapterTranslation")}
                  onClick={this.goToSpecificChapter}
                >
                  {tl("RedoChapterTranslation")}
                </p>
                <Tooltip
                  position="top"
                  trigger="mouseover"
                  distance={10}
                  theme="light"
                  unmountHTMLWhenHide={true}
                  arrow={true}
                  html={
                    <div className="Dad-tooltip Dad-tooltip--Report">
                      <p className="Dad-tooltip__description">
                        {
                          this.props.chapter.results[
                            Math.min(
                              Math.max(
                                Math.floor(this.props.chapter.myStats) - 1,
                                0
                              ),
                              this.props.chapter.results.length - 1
                            )
                          ].text
                        }
                      </p>
                    </div>
                  }
                >
                  <p
                    value={tl("DiagnosticHelper")}
                    className="Dad-link--secondary"
                  >
                    {tl("DiagnosticHelper")}
                  </p>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="OrientationCard__footer">
          <h4>{tl("GoingFurtherTranslation")}</h4>
          <ul>
            {this.props.links.map((l, i) => (
              <li key={i}>
                <Link value={l.label} href={l.url} _blank />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

OrientationCard.propTypes = {
  chapter: PropTypes.object,
  message: PropTypes.string,
  links: PropTypes.array,
  className: PropTypes.string,
};

export default OrientationCard;
