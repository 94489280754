import axios from '../config/axios';

const getRandomAnswerSet = async () => 
  await axios.get(`/contents/answers/`);

const answerQuestion = async (args) => 
  await axios.post(`/contents/answers/${args.chapterId}`, { args });

const updateCurrentChapter = async (chapter_id, question_id) => 
  await axios.post(`/chapters/currentChapter`, { chapter_id: chapter_id, question_id: question_id });

const getPreviousAnswer = async (args) => 
  await axios.get(`/contents/answers/previous`, { params: { chapter_id : args.chapter_id, question_id: args.question_id }});

const completeCurrentChapter = async (chapter_id) => 
  await axios.post(`/chapters/completeChapter`, { params: { chapter_id: chapter_id }})

export default {
  getRandomAnswerSet,
  answerQuestion,
  updateCurrentChapter,
  completeCurrentChapter,
  getPreviousAnswer,
}
