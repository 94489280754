import React, { Component } from 'react'
import PropTypes from 'prop-types';

import './style.scss';

class Arrow extends Component {
  constructor(props){
    super(props);
  }

  render () {
    return (
    <React.Fragment>
        <svg width="6px" height="4px" viewBox="0 0 6 4" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className={ this.props.className }>
          <g id="Bulletin-de-notes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="[Desktop]-Bravo-(vue-de-toutes-les-notes)" transform="translate(-1294.000000, -62.000000)" fill={ this.props.fill }>
                <g id="Group-21">
                  <g id="Group-11" transform="translate(135.000000, 32.000000)">
                    <g id="CPE-black">
                      <g id="Group-5" transform="translate(1025.000000, 9.000000)">
                        <g id="Group-2" transform="translate(129.000000, 14.000000)">
                          <g id="chevron-down" transform="translate(5.000000, 7.000000)">
                            <path d="M3.03042857,2.27742857 L5.15185714,0.156 C5.25946049,0.0445900352 5.41880481,-9.1113085e-05 5.568646,0.03912979 C5.71848718,0.0783506932 5.83550645,0.195369962 5.87472735,0.345211147 C5.91394826,0.495052332 5.86926711,0.654396656 5.75785714,0.762 L3.33342857,3.18642857 C3.16607149,3.35373512 2.89478565,3.35373512 2.72742857,3.18642857 L0.303,0.762 C0.191590035,0.654396656 0.146908887,0.495052332 0.18612979,0.345211147 C0.225350693,0.195369962 0.342369962,0.0783506932 0.492211147,0.03912979 C0.642052332,-9.1113085e-05 0.801396656,0.0445900352 0.909,0.156 L3.03042857,2.27742857 Z" id="Path"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
        </svg>  
    </React.Fragment>
    )
  }
}

Arrow.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
};

export default Arrow;
