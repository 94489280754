import React, { Component } from 'react'

import Label from 'components/atoms/Label';
import Select from 'components/atoms/Select';
import { tl } from 'utils/Translations';
import './style.scss';

class Region extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRegion : null
    }

    this.handleRegionSelect = this.handleRegionSelect.bind(this)
  }

  handleRegionSelect(region){
    this.props.handleRegionSelection(region);
  }

  render () {
    return (
      <div>
        <Label value={tl('MainRegisterRegionTitle')} />
        <Select 
          options={this.props.regions} 
          selectedOption={this.state.selectedRegion} 
          handleSelect={this.handleRegionSelect}/>
      </div>
    )
  }
}

export default Region;
