import React, {Component} from 'react'

import styles from './styles.scss';

import Button from 'components/atoms/Button'

class NickNameSelector extends React.Component {
  constructor(props){
    super(props)

    this.selectNickName = this.selectNickName.bind(this)
  }

  selectNickName(e){
    this.props.selectNickName({
      value : this.props.nickname
    })
  }

  render() {
    return (
      <div className="Nickname__item" onClick={this.selectNickName}>
        {this.props.nickname}
      </div>
    )
  }
}

export default NickNameSelector;
