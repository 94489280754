import axios from '../config/axios';
import ES6Promise from "es6-promise"

ES6Promise.polyfill();

const addFeedback = async (args) => 
  await axios.post(`/feedback/new`, { args });

const getUserFeedback = async () => 
  (await axios.get(`/feedback/`)).data;

export default {
  addFeedback,
  getUserFeedback
}
