import React, { Component } from "react";
import { Animated } from "react-animated-css";
// Atoms
import Button from "components/atoms/Button";
import IconChapter from "components/atoms/Icons/Chapter";
import IconDiagnostic from "components/atoms/Icons/Diagnostic";
// Molecules
import OrientationCard from "../../../molecules/OrientationCardReport";
// Organisms
import HeroReport from "components/organisms/Hero/Report";
import Loader from "components/organisms/Loader";
import HeaderPlanning from "components/organisms/Headers/HeaderPlanning";
import Modal from "components/organisms/Modal";
import NavBar from "components/organisms/NavBar";
// Templates
import Download from "./download.js";
// Factories
import Auth from "resources/auth";
import ChapterResource from "resources/chapter";
import FeedbackFactory from "resources/feedback";
import HomePageFactory from "resources/homepage";
import LinksFactory from "resources/links";
// Utils
import { tl } from "utils/Translations";
import html2canvas from "html2canvas";
//import jsPDF from "jspdf";
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modal: false,
      width: window.innerWidth,
      primaryColor: "#000",
      headerBackgroundColor: null,
      headerBackgroundImage: null,
      ...this.props.match.params,
    };

    this._selectChapter = this._selectChapter.bind(this);
    this.goToChapter = this.goToChapter.bind(this);
    this.fadeOut = this.fadeOut.bind(this);
    this.fadeIn = this.fadeIn.bind(this);
    this.handleScrollDown = this.handleScrollDown.bind(this);
    this.handleCursorClick = this.handleCursorClick.bind(this);
    this._viewAllChapters = this._viewAllChapters.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
    this._handleRatingSubmit = this._handleRatingSubmit.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  componentWillUpdate() {
    this.onResize;
  }

  async componentDidMount() {
    try {
      const user = await Auth.getUser();
      if (!!user) {
        const chapters = await ChapterResource.getAllChapters();
        const numberOfCompletedChapters = chapters.filter(
          (c) => c.progress.status === "DONE"
        ).length;

        if (numberOfCompletedChapters === chapters.length) {
          const feedback = await FeedbackFactory.getUserFeedback();
          const diagnostics = await ChapterResource.getAllChaptersDiagnostics();
          const links = await LinksFactory.getLinks();
          const homePageInfos = await HomePageFactory.getHomePageInfos({
            instance_alias: this.state.instance_alias,
          });
          const chaptersLowestRates = diagnostics
            .filter((c) => c.published)
            .sort((a, b) => a.myStats - b.myStats)
            .splice(0, 3);
          const chaptersOtherRates = diagnostics
            .filter((c) => chaptersLowestRates.indexOf(c._id) == -1)
            .sort((a, b) => a.myStats - b.myStats);
          const lowestIds = _.map(chaptersLowestRates, (e) => e._id);
          _.remove(
            chaptersOtherRates,
            (e) => _.indexOf(lowestIds, e._id) !== -1
          );
          const chapters = diagnostics.sort((a, b) => b.myStats - a.myStats);

          this.setState({
            user,
            loading: false,
            chapters,
            chaptersLowestRates,
            chaptersOtherRates,
            links,
            selectedChapter: chapters[0],
            userRating: feedback || { rating: null },
            visited: true,
            /* (window.localStorage[user._id] && //si on veut remttre la page intermediaire de diagnostic il faut décommenter
            JSON.parse(window.localStorage[user._id]).visited) ||
          false, */
            headerBackgroundImage: homePageInfos.navbarPath,
            headerBackgroundColor: homePageInfos.navbarColor,
          });
        }
      }
    } catch (e) {
      console.log(e);
      this.props.history.push(`/${this.state.instance_alias}/`);
    }
  }

  handleScrollDown(event) {
    if (event.nativeEvent.wheelDelta > 0) {
      this.setState(
        {
          visited: true,
        },
        () => {
          window.localStorage[this.state.user._id] = JSON.stringify({
            visited: true,
          });
        }
      );
    } else {
      return false;
    }
  }

  handleCursorClick() {
    this.setState(
      {
        visited: true,
      },
      () => {
        window.localStorage[this.state.user._id] = JSON.stringify({
          visited: true,
        });
      }
    );
  }
  onResize() {
    this.setState({
      width: window.innerWidth,
    });
  }

  downloadPDF(e) {
    e.preventDefault();
    document.querySelector(".diagnostic-download").style.display = "block";
    html2canvas(document.querySelector(".download"))
      .then((canvas) => {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;

        var pageHeight = (contentWidth / 595) * 840;
        var leftHeight = contentHeight;
        var position = 0;
        var imgWidth = 595;
        var imgHeight = (595 / contentWidth) * contentHeight;

        var pageData = canvas.toDataURL("image/jpeg", 1.0);

       /*  var pdf = new jsPDF("", "pt", "a4");

        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 840;
            if (leftHeight > 0 && leftHeight > 100) {
              pdf.addPage();
            }
          }
        }

        pdf.save("diagnostic.pdf"); */
      })
      .finally(() => {
        document.querySelector(".diagnostic-download").style.display = "none";
      });
  }

  fadeOut(el) {
    return new Promise((resolve) => {
      el.style.opacity = 1;

      function timer() {
        if (el.style.opacity > 0) el.style.opacity = el.style.opacity - 0.1;
        else {
          window.clearInterval(interval);
        }
      }
      var interval = setInterval(timer, 10);

      resolve();
    });
  }

  fadeIn(el) {
    return new Promise((resolve) => {
      var intervalFadeIn = setInterval(timer, 20);
      function timer() {
        if (el.style.opacity < 1) {
          el.style.opacity = Number(el.style.opacity) + 0.1;
        } else {
          window.clearInterval(intervalFadeIn);
        }
      }
      resolve();
    });
  }

  _selectChapter(chapter) {
    let legends = document.getElementsByClassName("legend-container");
    return Promise.all(Array.from(legends).map((el) => this.fadeOut(el))).then(
      () => {
        setTimeout(() => {
          legends = document.getElementsByClassName("legend-container");
          this.setState({
            selectedChapter: chapter,
          });
          return Promise.all(Array.from(legends).map((el) => this.fadeIn(el)));
        }, 1000);
      }
    );
  }

  _viewAllChapters() {
    this.setState({
      modal: true,
    });
  }

  _handleRatingSubmit(rating) {
    FeedbackFactory.addFeedback(rating)
      .then((feedback) => {
        this.setState({
          userRating: { rating: feedback.data.rating },
        });
      })
      .catch((feedbackError) => {
        console.error(feedbackError);
      });
  }

  goToChapter(event) {
    this.props.history.push(`chapters/${this.state.selectedChapter._id}`);
  }

  render() {
    const { chapters, loading, user, modal, instance_alias } = this.state;
    const { className } = this.props;
    const isMobile = this.state.width <= 768;
    const note =
      chapters &&
      Math.floor(
        chapters.reduce((a, b) => {
          return Number(a) + Number(b.myStats);
        }, 0) / chapters.length
      );

    if (this.state && loading) return <Loader />;
    else {
      if (!this.state.visited) {
        return (
          <React.Fragment>
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              className={`${this.props.className || ""}`}
              isVisible={true}
            >
              <HeroReport
                onClick={this.downloadPDF}
                handleCursorClick={this.handleCursorClick}
                onWheel={this.handleScrollDown}
                value={note.toFixed()}
              />
            </Animated>
            <div className="diagnostic-download">
              <Download nickname={user.nickname} />
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <div style={{ backgroundColor: "#FFFFFF" }}>
            <Modal
              open={modal}
              title={tl("SeeAllNotesTranslations")}
              _onClose={() => this.setState({ modal: false })}
            >
              {chapters.map((chapter) => (
                <Animated
                  key={chapter._id}
                  animationIn="bounceIn"
                  animationInDelay={150}
                  animationOut="fadeOut"
                  className={`${className || "col-md-3 txt-center"}`}
                  isVisible={true}
                >
                  <div className="Dad-card-report">
                    <p className="Dad-card-report__icon">
                      <img src={chapter.iconURL} alt="" />
                    </p>
                    <h1 className="Dad-card-report__name">{chapter.name}</h1>
                    <p className="Dad-card-report__stats">
                      <span className="current-stat">{chapter.myStats}</span> /
                      5
                    </p>
                  </div>
                </Animated>
              ))}
            </Modal>
            <HeaderPlanning
              {...this.props}
              backgroundImage={this.state.headerBackgroundImage}
              user={this.state.user}
              backgroundColor={this.state.headerBackgroundColor}
            >
              <NavBar
                displayDiagnosticLink={true}
                history={this.props.history}
                instanceAlias={this.state.instance_alias}
              />
            </HeaderPlanning>
            <div id="DadReport">
              {/* HERO */}
              <div className="Dad-hero">
                <div className="Dad-hero__container row">
                  <Animated
                    animationIn="slideInUp"
                    animationOut="fadeOutDown"
                    className={`col-12 col-md-3 animated-left`}
                    isVisible={true}
                  >
                    <h1 className="Dad-hero__title">
                      {tl("BravoTranslation")}
                    </h1>
                    <p className="Dad-hero__description">
                      {tl("ParcoursDoneTranslation")}
                    </p>
                    <Button
                      type="primary"
                      className="primary download-btn"
                      onClick={this.downloadPDF}
                    >
                      <img src={""} alt="" />
                      &nbsp;{" "}
                      <p className="txt">
                        {tl("DownloadDiagnosticTranslation")}
                      </p>
                    </Button>
                  </Animated>

                  <Animated
                    animationIn="slideInRight"
                    animationOut="fadeOutDown"
                    className="animated-right col-12 col-md-6 offset-md-1"
                    isVisible={true}
                  >
                    <div className="Dad-hero-card">
                      <div className="Dad-hero-card__top">
                        <p className="Dad-hero-card__top-title">
                          {tl("GlobalNoteTranslation")}
                        </p>
                        <h2 className="Dad-hero-card__note">
                          <span className="current-note">
                            {this.props.value || note}
                          </span>
                          /5
                        </h2>
                      </div>

                      <div className="bottom">
                        <h3 className="Dad-hero-card__bottom-title">
                          {tl("DadOrientationObeservation")}
                        </h3>
                        <p className="Dad-hero-card__bottom-observation">
                          {tl(`DadOrientationOrientationObservation_${note}`)}
                        </p>
                      </div>
                    </div>
                  </Animated>
                </div>
              </div>

              {/* SUBHERO */}
              <div className="sub-hero">
                <div className="sub-hero-inner">
                  <div className="text">
                    <p className="title">{tl(`DadReportWhatElse`)}</p>
                    <p className="desc">{tl(`DadReportDescription`)}</p>
                  </div>
                  <div className="links">
                    {this.state.links.map(({ label, link }) => (
                      <a href={link} target="_blank">
                        {label}
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              <div className="results-panel">
                <h3 className="title">{tl(`DadReportCustomCourses`)}</h3>
                <div className="results lowest-results">
                  {this.state.chaptersLowestRates.map((chapter, i) => (
                    <OrientationCard
                      history={this.props.history}
                      key={chapter._id}
                      className="orientation-card Dad-Orientation__ratings--container--card"
                      links={
                        chapter.results[
                          Math.min(
                            Math.max(Math.floor(chapter.myStats) - 1, 0),
                            chapter.results.length - 1
                          )
                        ].links
                      }
                      message={
                        chapter.results[
                          Math.min(
                            Math.max(Math.floor(chapter.myStats) - 1, 0),
                            chapter.results.length - 1
                          )
                        ].text
                      }
                      chapter={chapter}
                      user={this.state.user}
                      index={`__1__${i}`}
                    />
                  ))}
                </div>

                <h3 className="title">
                  {tl(`DadReportOrientationOtherThemes`)}
                </h3>
                <div className="results other-results">
                  {this.state.chaptersOtherRates.map((chapter, i) => (
                    <OrientationCard
                      history={this.props.history}
                      key={chapter._id}
                      className="orientation-card Dad-Orientation__ratings--container--card"
                      links={
                        chapter.results[
                          Math.min(
                            Math.max(Math.floor(chapter.myStats) - 1, 0),
                            chapter.results.length - 1
                          )
                        ].links
                      }
                      message={
                        chapter.results[
                          Math.min(
                            Math.max(Math.floor(chapter.myStats) - 1, 0),
                            chapter.results.length - 1
                          )
                        ].text
                      }
                      chapter={chapter}
                      user={this.state.user}
                      index={`__2__${i}`}
                    />
                  ))}
                  <div className="orientation-card Dad-Orientation__ratings--container--card orange-card">
                    <p>{tl(`DadReportGiveYourFeedback`)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="diagnostic-download">
              <Download nickname={this.state.user.nickname} />
            </div>
          </div>
        );
      }
    }
  }
}

export default Report;
