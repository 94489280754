import React, { Component } from "react";
import { tl, use } from "utils/Translations";
import "./style.scss";

import AuthFactory from "resources/auth";
import HomePageFactory from "resources/homepage";
import TrackFactory from "resources/tracking";
import InstanceFactory from "resources/instance";

// Assets
import IconGhost from "assets/images/icon-ghost.svg";
import IconGame from "assets/images/icon-game.svg";
import IconCompass from "assets/images/icon-compass.svg";

import Catherine from "assets/images/CatherineDupont.jpg";
import Pierre from "assets/images/PierreFevrier.jpg";
import MarieLise from "assets/images/MarieLiseLeroy.jpg";

// Atoms
import Button from "components/atoms/Button";

// Molecules
import Testimonial from "components/molecules/Testimonial";
import ListIcon from "components/molecules/ListIcon";

// Organisms
import HeaderLanding from "components/organisms/Headers/HeaderLanding";
import HeroLanding from "components/organisms/Hero/Landing";
import Editorial from "components/organisms/Editorial";
import Player from "components/organisms/Player";
import Footer from "components/organisms/Footer";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      testimonials: [
        {
          firstName: tl("LandingPersona1FirstName"),
          lastName: tl("LandingPersona1LasttName"),
          title: "LandingPersona1Title",
          description: "LandingPersona1Description",
          img: Pierre,
        },
        {
          firstName: tl("LandingPersona2FirstName"),
          lastName: tl("LandingPersona2LasttName"),
          title: "LandingPersona2Title",
          description: "LandingPersona2Description",
          img: MarieLise,
        },
        {
          firstName: tl("LandingPersona3FirstName"),
          lastName: tl("LandingPersona3LasttName"),
          title: "LandingPersona3Title",
          description: "LandingPersona3Description",
          img: Catherine,
        },
      ],
      benefits: [
        {
          image: IconGhost,
          title: "LandingBenefitTitle1",
          description: "LandingBenefitDescription1",
        },
        {
          image: IconGame,
          title: "LandingBenefitTitle2",
          description: "LandingBenefitDescription2",
        },
        {
          image: IconCompass,
          title: "LandingBenefitTitle3",
          description: "LandingBenefitDescription3",
        },
      ],
      homepage: {
        posterUrl: null,
        videoUrl: null,
      },
      instance_alias: props.match.params.instance_alias,
      instance_id: null,
      headerBackgroundColor: null,
      headerBackgroundImage: null,
      landingBackrgoundImage: null,
    };

    this._handleClick = this._handleClick.bind(this);
  }

  componentDidMount() {
    TrackFactory.track(this.state.instance_alias);
    return InstanceFactory.checkInstanceExists({
      instance_alias: this.state.instance_alias,
    })
      .then((resu) => {
        if (resu === false) {
          this.props.history.push("/");
        }
        return HomePageFactory.getHomePageInfos({
          instance_alias: this.state.instance_alias,
        }).then((infos) => {
          return AuthFactory.isAuthenticated()
            .then((loggedResponse) => {
              //if connected then redirect auto
              this.props.history.push(`/${this.state.instance_alias}/chapters`);
            })
            .catch((err) => {
              //or not connected returns a 401 from server

              let video = {
                posterUrl: infos !== null ? infos.posterUrl : null,
                videoUrl: infos !== null ? infos.videoUrl : null,
              };

              // testimonials
              let preTestimonials = this.state.testimonials;
              preTestimonials[0].img = infos.testiMonials1;
              preTestimonials[1].img = infos.testiMonials2;
              preTestimonials[2].img = infos.testiMonials3;

              // background customizations
              if (infos.backGroundPath !== null) {
                this.state.landingBackrgoundImage = infos.backGroundPath;
              }

              // header customization
              if (infos.navbarPath !== null) {
                this.state.headerBackgroundImage = infos.navbarPath;
              }
              if (infos.navbarColor !== null) {
                this.state.headerBackgroundColor = infos.navbarColor;
              }

              let body = document.getElementsByTagName("body");
              body[0].classList.add("body-remove-background");

              this.setState({
                homepage: video,
                loading: false,
                instance_id: infos.instance._id,
              });
              console.error(err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
        this.props.history.push("/");
      });
  }

  componentWillUnmount() {
    let body = document.getElementsByTagName("body");
    body[0].classList.remove("body-remove-background");
  }

  _handleClick() {
    this.props.history.push(`/${this.state.instance_alias}/qualification`);
  }

  render() {
    if (this.state.loading) return <div></div>;
    return (
      <div className="Landing-page">
        <HeaderLanding
          backgroundImage={this.state.headerBackgroundImage}
          backgroundColor={this.state.headerBackgroundColor}
        >
          <Button
            className="primary"
            type="primary"
            onClick={this._handleClick}
          >
            {tl("LandingPageStartButton")}
          </Button>
        </HeaderLanding>

        <HeroLanding
          _handleClick={this._handleClick}
          backgroundImage={this.state.landingBackrgoundImage}
        />

        <div className="Landing-page__section">
          <h2 className="Landing-page__title">{tl("LandingPageWhy")}</h2>
          <Editorial />
        </div>

        <div className="Landing-page__section Landing-page__video-wrapper">
          <Player
            src={this.state.homepage.videoUrl}
            poster={this.state.homepage.posterUrl}
          />
        </div>

        <div className="Landing-page__section">
          <div className="container">
            <ul className="Landing-page__list row">
              {_.map(this.state.benefits, (b, i) => {
                return (
                  <div className="col-md-6 col-lg-4" key={i}>
                    <ListIcon obj={b} />
                  </div>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="Landing-page__section">
          <div className="container">
            <h2 className="Landing-page__title">
              {tl("LandingPageTestimonialsTitle")}
            </h2>
            <div className="row">
              {_.map(this.state.testimonials, (t, i) => {
                return (
                  <div className="col-md-6 col-lg-4" key={i}>
                    <Testimonial testimonial={t} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>mon footer ?</div>
        <Footer match={this.props.match} instance_id={this.state.instance_id} />
      </div>
    );
  }
}

export default Landing;
