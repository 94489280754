import React from 'react';
import './style.scss';
import { Animated } from "react-animated-css";


export default class ModalVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: this.props.open || false, videoURL: props.videoURL, title: props.title, description: props.description, poster: props.poster };
    this.close = this.close.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.open !== prevState?.open ||
      nextProps?.videoURL !== prevState?.videoURL ||
      nextProps?.title !== prevState?.title ||
      nextProps?.description !== prevState?.description ||
      nextProps?.poster !== prevState?.poster) {
      return {
        open: nextProps.open,
        videoURL: nextProps.videoURL,
        title: nextProps.title,
        description: nextProps.description,
        poster: nextProps.poster
      };
    }
    return null;
  }

  close() {
    this.setState({ open: false });
    this.props._onClose();
  }
  render() {
    return (
      <div className={`Dad-modal-video ${this.state.open ? 'Dad-modal-video--open' : 'Dad-modal-video--hide'}`}>
        <div className="container">
          <div className="Dad-modal-video__head">
            <div className="Dad-modal__title"></div>
            <span className="Dad-modal__close" onClick={this.close}></span>
          </div>
          <div className="Dad-modal-video__body row">
            <div className="Dad-modal-video__content offset-md-1 col-md-4">
              <h1 className="Dad-modal-video__title">
                {this.state.title}
              </h1>
              <p className="Dad-modal-video__description">
                {this.state.description}
              </p>
            </div>
            <div className="Dad-modal-video__player col-md-6">
              <video muted
                id="chapterVideo"
                controls="controls"
                poster={this.state.poster}
                width="100%">
                <source src={this.state.videoURL} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
