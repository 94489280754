import React from 'react';
import './style.scss';

class Button extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <button
        className={`Dad-Button Dad-Button--${this.props.className} Dad-Button--${this.props.size} ${ this.props.disabled ? 'disabled' : ''}`}
        disabled={this.props.disabled}
        type={this.props.type}
        onClick={this.props.disabled ? null : this.props.onClick}>
        {this.props.children}
      </button>
    );
  }
}
export default Button;
