import React, { Component } from 'react';
import '../global.scss';
import './style.scss';

import {Animated} from "react-animated-css";
// Atoms 
import Button from 'components/atoms/Button';
import { tl } from 'utils/Translations';

// Assets
import IconDownload from 'assets/images/icon-download.svg';


class HeroReport extends Component {
  constructor(props){
    super(props);

  }

  componentDidMount() {
    var mousewheelevt = (/Firefox/i.test(navigator.userAgent)); //FF doesn't recognize mousewheel as of FF3.x

    let doc = document.getElementById("heroreport");
    if(mousewheelevt){
      doc.addEventListener("DOMMouseScroll",this.props.handleCursorClick)
    }
  }

  render () {
    return (
      <section id="heroreport" className="Dad-hero Dad-hero--report" onWheel={this.props.onWheel} onWheelUp={this.props.onWheel}>
          <div className="Dad-hero__container row">
            <Animated
              animationIn="slideInUp"
              animationOut="fadeOutDown"
              className={`${this.props.className || 'col-12 col-md-3' }`}
              isVisible={true}>
              <h1 className="Dad-hero__title">{tl('BravoTranslation')}</h1>
            <p className="Dad-hero__description">{tl('ParcoursDoneTranslation')}</p>
              <Button type="primary" className="primary" onClick={ (e) => this.props.onClick(e) }>
              <img src={IconDownload} alt="" />&nbsp; {tl('DownloadDiagnosticTranslation')}
              </Button>
            </Animated>
            
            <Animated
              animationIn="slideInRight"
              animationOut="fadeOutDown"
              className={`${this.props.className || 'col-12 col-md-3 offset-md-1' }`}
              isVisible={true}>
                <div className="Dad-hero-card">
                  <div className="Dad-hero-card__top">
                <p className="Dad-hero-card__top-title">{tl('GlobalNoteTranslation')}</p>
                    <h2 className="Dad-hero-card__note">
                      <span className="current-note">{ this.props.value || 0}</span>/5
                    </h2>
                  </div>
                  <div className="Dad-hero-card__bottom">
                  <h3 className="Dad-hero-card__bottom-title">{tl('ObservationTranslation')}</h3>
                    <p className="Dad-hero-card__bottom-description">
                      {tl(`DadOrientationOrientationObservation_${this.props.value}`) }
                    </p>
                  </div>
                </div>
            </Animated>
          </div>
          <div className="Dad-hero__scroll-down">
            <p>{tl('ShowNotesPerMatterTranslation')}</p>
          <div className="icon-mouse" onClick={this.props.handleCursorClick}>
              <span className="icon-mouse__wheel"></span>
            </div>
          </div>
      </section>
    )
  }
}


export default HeroReport;
