import axios from 'axios';

const protocol = process.env.NODE_ENV === 'production' ? 'https' : 'http';
const BASE_API_URL = `${protocol}://${process.env.NODE_HOST}:${process.env.NODE_PORT}/api/v1`;

export default axios.create({
  withCredentials: true,
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
  },
});
