import React, { Component } from 'react'
import PropTypes from 'prop-types';
import DefaultAvatar from 'assets/images/avatar-default.svg';
import './style.scss';


class Avatar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: props.image,
      size: props.size || 'S'
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.image !== prevState.image) {
      return { image: nextProps.image };
    }
    return null;
  }

  render() {
    const styles = {
      backgroundImage: `url(${this.state.image})`
    }
    return (
      <div
        className={`Dad-avatar Dad-avatar--${this.props.size} ${this.props.className}`}
        style={styles}
        onClick={this.props.onClick}>
      </div>
    )
  }
}

Avatar.defaultProps = {
  size: 'M'
};

Avatar.propTypes = {
  image: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
}

export default Avatar;
