import axios from '../config/axios';

const checkInstanceExists = async (args) =>
  (await axios.get(`/instance/check/${args.instance_alias}`)).data;

const getAll = async () =>
  (await axios.get(`/instance`)).data;

export default {
  checkInstanceExists,
  getAll
}
