import React, { Component} from 'react'
import style from './style.scss';

class Player extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="Dad-player">
        <video 
          controls 
          muted
          className="content col-md-10 col-lg-8 offset-md-1 offset-lg-2" 
          src={ this.props.src} 
          poster={ this.props.poster }></video>
      </div>
    )
  }
}
export default Player;
