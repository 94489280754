import React, { Component } from 'react';
import { Tooltip } from 'react-tippy';
import Arrow from 'components/atoms/Arrow';

import { tl } from 'utils/Translations';

import './style.scss';
import dadLogo from 'assets/images/dad-logo.svg';
import Auth from 'resources/auth';
import TrackingFactory from 'resources/tracking';
import LinksFactory from 'resources/links';
import Link from 'components/atoms/Link';

import Button from 'components/atoms/Button';

import Modal from 'components/organisms/Modal';

class HeaderPlanning extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth, links: [], modal: false, open: false, tooltipMobileOpen: false, ...this.props.match.params };
    this._handleSidnav = this._handleSidnav.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.setIsOpen = this.setIsOpen.bind(this);
    this.handleMobileMenu = this.handleMobileMenu.bind(this)
    this.setMobileOpen = this.setMobileOpen.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);

    return LinksFactory.getLinks()
      .then(links => {
        this.setState({ links });
      });
  };

  setIsOpen(option) {
    this.setState({
      open: option
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    // document.body.removeEventListener('click', () => this.setIsOpen(false));
  }

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth });
  };

  _handleSidnav() {
    this.setState({ sidenav: !this.state.sidenav })
  }

  _handleClick(l) {
    this.setState({ sidenav: false }, () => {
      this.props._handleClick(l);
    })
  };

  disconnect() {
    return Auth.logout()
      .then(() => {
        this.props.history.push(`/${this.state.instance_alias}/qualification`)
      })
  };

  handleLinkClick(link) {
    TrackingFactory.trackLinkClicked({
      link_id: link._id
    })
  }

  handleMobileMenu () {
    if (this.state.menuMobile === true) {
      this.setState({
        menuMobile: false
      })
    } else {
      this.setState({
        menuMobile: true
      })
    }
  }

  setMobileOpen(option){

    this.setState({
      tooltipMobileOpen: option
    }, () => {
      if(option === false){
        document.querySelector('.tooltip-mobile').click(); // hide tooltip when user click on menu item
      }
    })
  }

  render() {
    if (!this.state) return;

    const { width } = this.state;
    const isMobile = width <= 768;

    let _this = this;

    let styles = {}

    if (this.backgroundColor !== null) {
      styles['background'] = 'black'
    }

    if (this.props.backgroundImage !== null) {
      styles['backgroundImage'] = `url(${this.props.backgroundImage})`;
      styles['backgroundSize'] = "cover";
      styles["backgroundRepeat"] = "no-repeat";
    }

    return (
      <header className="Dad-header" id="Header" style={styles}>
        <Modal
          open={this.state.modal}
          _onClose={() => this.setState({ modal: false })}
          isClosable
          modalClass={`Modal Modal--disconnection ${isMobile ? 'Modal--disconnection--mobile' : ''}`}>
          <div className="container">
            <div className="col-md-4 offset-md-4 Modal--disconnection--container">
              <div className="Modal--disconnection--container-body">
                <h1> {tl('SeeYouSoon')} </h1>
                <span>{tl('SeeYouSoonThanks')}</span>
              </div>
              <div className="Modal--disconnection--container-footer">
                <Link className="Dad-link--secondary" value={tl('GoBack')} onClick={() => this.setState({ modal: false })} />
                <Button
                  className="primary"
                  type="primary"
                  onClick={this.disconnect}>
                  {tl('HeaderBye')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <div className="Dad-header__container">
          <div className="Dad-header__brand">
            <img src={dadLogo} alt="Orange" />
          </div>

          {isMobile && 
          <Tooltip
            trigger="click"
            interactive
            hideOnClick="true"
            direction="bottom"
            distance={30}
            arrow={true}
            className={"tooltip-mobile"}
            onRequestClose={() => { this.setMobileOpen(false) }}
            html={(
              <ul className="Dad-tooltip">
                <li className="Dad-tooltip-link" onClick={() => { this.props.history.push(`/${this.props.match.params.instance_alias}/cgu`) }}>{tl('DadTooltip__CGU')}</li>
                <li className="Dad-tooltip-link" onClick={() => { this.setState({ modal: true }, () => this.setMobileOpen(false)); }}>{tl('DadTooltip__Disconnect')}</li>
              </ul>
            )}
          >
            <div className="Dad-header__avatar--mobile"
            style={{ backgroundImage: 'url(' + this.props.user.avatar.path +')'}}
            >
              {/* {this.props.user.avatar ? this.props.user.avatar.path : null} */}
            </div>
          </Tooltip>
          }

          <div className={`Dad-header__trigger-nav-mobile ${this.state.menuMobile ? 'Dad-header__trigger-nav-mobile--cross' : ''}`} onClick={this.handleMobileMenu}>
            <span></span>
          </div>

          <nav className={`Dad-header__nav-mobile ${this.state.menuMobile ? 'Dad-header__nav-mobile--visible' : ''}`}>
            {this.props.children}
            <hr className="separator" />
            <ul className="helpful-links">
              <li>
                <strong>{tl('HeaderLinks')}</strong>
                <ul>
                  {
                    this.state.links.map(l => {
                      return (<li className="" key={l._id} onClick={() => this.handleLinkClick(l)}>
                        <a href={l.link} target="_blank"> {tl(l.label)}</a>
                      </li>)
                    })
                  }
                </ul>
              </li>
            </ul>
          </nav>

          <nav className="Dad-header__Links">
            {this.props.children}
            <div>
              <Tooltip
                trigger="click"
                interactive
                unmountHTMLWhenHide="true"
                onRequestClose={() => { this.setIsOpen(false) }}
                html={(
                  <ul className="Dad-tooltip">
                    {
                      this.state.links.map(l => {
                        return (<li className="Dad-tooltip-link" key={l._id} onClick={() => this.handleLinkClick(l)}>
                          <a href={l.link} target="_blank"> {tl(l.label)}</a>
                        </li>)
                      })
                    }
                  </ul>
                )}
              >
                <div className="Dad-header-link" >
                  {tl('HeaderLinks')}
                  <Arrow fill="#FFF" className="arrow-link" />
                  &emsp;
                </div>
              </Tooltip>
            </div>
            <div>
              <Tooltip
                trigger="click"
                interactive
                unmountHTMLWhenHide="true"
                open={this.state.open}
                onShow={this.test}
                onRequestClose={() => { this.setIsOpen(false) }}
                html={(
                  <ul className="Dad-tooltip">
                    <li className="Dad-tooltip-link" onClick={() => { this.props.history.push(`/${this.props.match.params.instance_alias}/cgu`) }}>{tl('DadTooltip__CGU')}</li>
                    <li className="Dad-tooltip-link" onClick={() => { this.setState({ modal: true }, () => this.setIsOpen(false)); }}>{tl('DadTooltip__Disconnect')}</li>
                  </ul>
                )}
              >
                <div className="Dad-header-link" onClick={() => this.setIsOpen(true)}>
                  <img src={this.props.user.avatar ? this.props.user.avatar.path : null} />
                  {this.props.user.plazza_name ? this.props.user.plazza_name : this.props.user.nickname}
                  <Arrow fill="#FFF" className="arrow-link" />
                </div>
              </Tooltip>
            </div>
          </nav>
        </div>
        <Modal>
          <div className="Dad-header-deconnexion col-md-4 offset-md-4">
            <h1 className="Dad-header-deconnexion__title">
              {tl('HeaderBye')}
            </h1>
            <p className="Dad-header-deconnexion__description">
            </p>
            
          </div>
        </Modal>
      </header>
    )
  }
};


export default HeaderPlanning;
