import React from 'react';
import './style.scss';
import { Animated } from "react-animated-css";

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: this.props.open || false };
    this.close = this.close.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.open !== prevState?.open) {
      return { open: nextProps.open };
    }
    return null;
  }

  close() {
    this.setState({ open: false });
    this.props._onClose();
  }
  render() {
    return (
      <div className={`Dad-modal ${this.props.modalClass} ${this.state.open ? 'Dad-modal--open' : 'Dad-modal--hide'}`}>
        <div className="container">
          {
            this.props.modalClass == 'Modal Modal--disconnection' ? null : (
              <div className="Dad-modal__head">
                <h1 className="Dad-modal__title">
                  {this.props.title}
                </h1>

                <div className="Dad-modal__close" onClick={this.close}></div>
              </div>
            )
          }
          <div className="Dad-modal__content">
            <div className="row">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );

  }
}
