import React, { Component } from 'react';
import { tl } from 'utils/Translations';
import Subtitle from 'components/molecules/Subtitle';
import './style.scss';



import IconQuote from 'assets/images/icon-quote.svg';

class Testimontial extends Component {
  constructor(props) {
    super(props);
    this.state = { testimonial: props.testimonial, className: props.className };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.testimonial !== prevState?.testimonial) {
      return {
        testimonial: nextProps.testimonial
      };
    }
    return null;
  }

  render() {
    return (
      <div
        className={`Dad-Testimonial ${this.state.className}`}
        style={{ backgroundImage: `url(${this.state.testimonial.img})` }}
      >
        <div className="Dad-Testimonial__content">
          <h2 className="Dad-Testimonial__title">
            {this.state.testimonial.firstName} {this.state.testimonial.lastName}
          </h2>
          <h3 className="Dad-Testimonial__subtitle">
            {tl(this.state.testimonial.title)}
          </h3>

          <p className="Dad-Testimonial__quote">
            {tl(this.state.testimonial.description)}
          </p>
        </div>
        <div className="Dad-Testimonial__icon">
          <img src={IconQuote} />
        </div>
      </div>
    )
  }
}

export default Testimontial;
