import React from 'react';
import './style.scss';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = { onFocus: false, value: this.props.value ? this.props.value : '', type: this.props.type || 'text' };
    this.handleChange = this.handleChange.bind(this);
    this.handlerFocus = this.handlerFocus.bind(this);
    this.handlerBlur = this.handlerBlur.bind(this);
  }
  handlerFocus() {
    this.setState({ onFocus: true });
  }

  handlerBlur() {
    if (this.props.onBlur) this.props.onBlur();
    this.setState({ onFocus: false });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    this.props.onInputChange({ value: event.target.value, field: this.props.name, obj: this.props.obj });
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.value && !this.state.value.length) this.refs.myTextInput.value = this.props.value;
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.value !== prevState?.value) {
      return {
        value: nextProps.value,
      };
    }
    return null;
  }

  render() {

    return (
      <div
        className={[
          'Dad-input',
          this.props.className,
          this.state.onFocus && 'Dad-input--focused',
          !this.state.value && 'Dad-input--empty',
        ].filter(x => x).join(' ')}
      >
        <input
          name={this.props.placeholder}
          type={this.state.type}
          autoComplete="false"
          autoFocus={this.props.autoFocus}
          onFocus={this.handlerFocus}
          onBlur={this.handlerBlur}
          pattern={this.props.pattern}
          required={this.props.required}
          title={this.props.title}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxlength}
          ref="myTextInput"
          value={this.state.value}
          min={this.props.min}
          max={this.props.max}
          onChange={this.handleChange}
          readOnly={this.props.readOnly}
          accept={this.props.accept}
          disabled={this.props.disabled}
        />
        <i className={this.props.icon} ></i>
        {this.props.children && <div className="Input__transclusion">{this.props.children}</div>}
      </div>
    );
  }
}
export default Input;
