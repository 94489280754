import React, { Component } from 'react'
import PropTypes from 'prop-types';

import './style.scss';

class Undo extends Component {
  constructor(props){
    super(props);
  }

  render () {
    return (
    <React.Fragment>
      <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className={ this.props.className }>
          <g id="Bulletin-de-notes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="toFill" transform="translate(-749.000000, -1487.000000)" fill={ this.props.fill } fillRule="nonzero">
                  <g id="Group-30" transform="translate(749.000000, 1487.000000)">
                      <g id="Group-34">
                          <g id="Group-33">
                              <g id="Group-27">
                                  <g id="history">
                                      <path d="M19.106,2.929 C20.9658541,4.81182983 22.0060939,7.35347993 22,10 C22,15.523 17.576,20 12.118,20 L12.118,18 C16.484,18 20.024,14.418 20.024,10 C20.0290633,7.88253259 19.1965212,5.84898755 17.708,4.343 C16.2345098,2.84360651 14.2202259,1.99933689 12.118,2 C8.574,2 5.574,4.36 4.571,7.612 L5.923,6.689 C6.14010631,6.54077923 6.40761736,6.48589478 6.66554775,6.53665368 C6.92347813,6.58741257 7.15024527,6.73956751 7.295,6.959 C7.60019715,7.41690273 7.48129733,8.03500363 7.028,8.347 L3.751,10.584 C3.53389369,10.7322208 3.26638264,10.7871052 3.00845225,10.7363463 C2.75052187,10.6855874 2.52375473,10.5334325 2.379,10.314 L0.17,6.998 C-0.135869888,6.53990059 -0.0169116982,5.92105068 0.437,5.609 C0.654106315,5.46077923 0.921617362,5.40589478 1.17954775,5.45665368 C1.43747813,5.50741257 1.66424527,5.65956751 1.809,5.879 L2.648,7.138 C3.863,3.01 7.643,0 12.118,0 C14.847,0 17.318,1.12 19.106,2.929 Z" id="Shape"></path>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    </React.Fragment>
    )
  }
}

Undo.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
};

export default Undo;
